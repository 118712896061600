import { useEffect, useRef, useState } from "react";

import { Draggable } from "react-beautiful-dnd";
import { useLocation, useParams } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Editor } from "primereact/editor";
import { DataTable } from "primereact/datatable";
import { confirmPopup, ConfirmPopup } from "primereact/confirmpopup";

import useAxios from "../../../../../hooks/useAxios";
import SwappedBillableTable from "./SwappedBillableTable";
import useTokenData from "../../../../../hooks/useTokenData";

function RenderRule({
  rule,
  allRules,
  setAllRules,
  ruleIndex,
  index,
  ediFunc,
}) {
  const toast = useRef(null);
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const location = useLocation();
  const isCopy = location?.state?.isCopy;
  const { serviceId, isTemplate } = useParams();

  const [locationData, setLocationData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);

  const numberConditions = [
    {
      label: "equal",
      value: "equal",
    },
    {
      label: "Bigger than",
      value: "biggerNumber",
    },

    {
      label: "Smaller than",
      value: "smallerNumber",
    },
    {
      label: "Bigger than or equal",
      value: "biggerThanOrEqual",
    },
    {
      label: "Smaller than or equal",
      value: "smallerThanOrEqual",
    },
    {
      label: "Between",
      value: "between",
    },
  ];

  let text = "";
  switch (rule.action) {
    case "abort":
      text = "Abort";
      break;
    case "addCPT":
      text = "Add Billable Items";
      break;
    case "removeCPT":
      text = "Remove Billable Items";
      break;
    case "removeAllExcept":
      text = "Remove All Except";
      break;
    case "removeMatchingPattern":
      text = "Remove Any Matching Pattern";
      break;
    case "AddUnitsToExisting":
      text = "Add Units To Existing Billable Items";
      break;
    case "removeUnitsFromExisting":
      text = "Remove Units From Existing Billable Items";
      break;
    case "addLocations":
      text = "Add Locations";
      break;
    case "removeLocations":
      text = "Remove Locations";
      break;
    case "addDocs":
      text = "Add Documents";
      break;
    case "removeDocs":
      text = "Remove Documents";
      break;
    case "swapCPT":
      text = "Replace Billable Item";
      break;
    default:
      break;
  }

  const getLocations = async () => {
    try {
      const response = await http.put(
        serviceId && !isCopy
          ? `/Locations/GetAllServiceLocations/${serviceId}`
          : "/Locations/GetLocationsByIds",
        rule.locations,
      );
      setLocationData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceDocs = async () => {
    try {
      const response = await http.get(
        `/EntityDocuments/GetAllEntityDocuments/${entityId}`,
      );
      const ruleDocs = response.data?.filter((doc) =>
        rule.documents.includes(doc.id),
      );
      setDocumentsData(ruleDocs);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    allRules[index]?.locations?.length && getLocations();
  }, [allRules[index]?.locations]);

  useEffect(() => {
    allRules[index]?.documents?.length && getServiceDocs();
  }, [allRules[index]?.documents]);

  const toReadableCondition = (condition) => {
    const conditions = {
      equal: "Equal",
      notEqual: "Not Equal",
      notAnswered: "Not Answered",
    };
    return conditions[condition];
  };

  const handleMoveToTop = () => {
    setAllRules((prevRules) => {
      const updatedRules = Array.from(prevRules);
      const [removed] = updatedRules.splice(index, 1);
      updatedRules.unshift(removed);
      return updatedRules;
    });
  };

  const handleMoveToBottom = () => {
    setAllRules((prevRules) => {
      const updatedRules = Array.from(prevRules);
      const [removed] = updatedRules.splice(index, 1);
      updatedRules.push(removed);
      return updatedRules;
    });
  };
  const isBillableItemUsed = (rules, id) => {
    return rules.some(
      (rule) =>
        rule?.defaultUnitsChange?.some((b) => b.id === id) ||
        (rule?.action === "removeCPT" &&
          rule?.billableItems?.some((b) => b.id === id)),
    );
  };

  const showBillableItemInUseToast = () => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: (
        <p>
          Deleting this rule will affect a billable item that is already used by
          another rule.
          <strong> Please review your rule settings before saving.</strong>
        </p>
      ),
      life: 4000,
    });
  };

  const accept = () => {
    if (
      allRules[index].action === "addCPT" &&
      allRules[index].billableItems.some((b) =>
        isBillableItemUsed(allRules, b.id),
      )
    ) {
      showBillableItemInUseToast();
      return;
    }
    const newRules = [...allRules];
    newRules.splice(index, 1);
    setAllRules(newRules);
    toast.current.show({
      severity: "success",
      summary: "Confirmed",
      detail: "Rule deleted successfully",
      life: 3000,
    });
  };

  const handleDel = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Do you wish to delete this rule?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
    });
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <label className="mb-3 block text-lg font-semibold">
          Abort message:
        </label>
      </span>
    );
  };

  const header = renderHeader();

  const locationBodyTemplate = (locationData) => {
    return (
      <div className="px-2">
        <h4>
          <span
            className={`font-medium capitalize ${locationData.isEnabled ? "text-gray-600" : "text-gray-400"}`}
          >
            {locationData.name}{" "}
          </span>
          {!locationData.isEnabled && (
            <span className="text-sm italic text-gray-400">(disabled)</span>
          )}
        </h4>
        <p className="font-normal capitalize text-gray-500">{`${
          locationData.address.lineOne
        }, ${
          locationData.address.lineTwo ? locationData.address.lineTwo + "," : ""
        } ${locationData.address.city}, ${locationData.address.state} ${
          locationData.address.zipCode
        }`}</p>
      </div>
    );
  };

  function convertToFeetAndInches(number) {
    const feet = Math.floor(number);
    const inches = Math.round((number - feet) * 12);
    return `${feet} (ft) ${inches} (in)`;
  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmPopup />
      <Draggable draggableId={index.toString()} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="m-2 border-2 p-4">
              <div className="items-center">
                <div className="flex items-center justify-between">
                  <div>
                    <label className="mr-2 border-b-4 text-xl font-semibold text-[#3D3877]">
                      Rule:-
                    </label>
                  </div>
                  <div className="flex flex-col">
                    <Button
                      type="button"
                      icon="pi pi-arrow-circle-up text-2xl"
                      onClick={handleMoveToTop}
                      className=""
                      link
                      tooltip="move top"
                      tooltipOptions={{
                        showDelay: 1000,
                        hideDelay: 300,
                        position: "bottom",
                      }}
                    />
                    <Button
                      type="button"
                      icon="pi pi-arrow-circle-down text-2xl"
                      onClick={handleMoveToBottom}
                      className=""
                      link
                      tooltip="move bottom"
                      tooltipOptions={{
                        showDelay: 1000,
                        hideDelay: 300,
                        position: "bottom",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card-all flex pt-3">
                <div className="left basis-1/2">
                  <div>
                    <label className="mb-2 text-lg font-semibold">
                      {" "}
                      Rule name :{" "}
                    </label>
                    <span>{rule.name}</span>
                  </div>
                  <div>
                    <label className="mb-2 text-lg font-semibold">
                      {" "}
                      Rule action :{" "}
                    </label>
                    <span>{text}</span>
                  </div>
                  <div>
                    <div>
                      <label className="mb-2 text-lg font-semibold">
                        {" "}
                        Rule type :{" "}
                      </label>
                      <span>{rule.type}</span>
                    </div>
                  </div>
                </div>

                <div className="right basis-1/2">
                  <h3 className="mb-1 text-lg font-semibold">Conditions:</h3>
                  <div>
                    {rule.type === "single"
                      ? rule.conditions.map((condition, index) => (
                          <div key={index}>
                            <label className="font-medium capitalize leading-loose text-gray-500">
                              Question {index + 1}:{" "}
                            </label>
                            <span>{condition.question.label}</span>
                            <label className="text-gray-5002 px-2 font-medium capitalize leading-loose">
                              {toReadableCondition(condition.questionCondition)}
                            </label>
                            {condition.questionCondition !== "notAnswered" &&
                              (["number", "decimal"].includes(
                                condition.question.type,
                              ) ? (
                                condition.numberComparisionCondition ===
                                "between" ? (
                                  <span>
                                    between{" "}
                                    {[
                                      condition?.question?.numerictype,
                                      condition?.question?.constraints?.type,
                                    ].includes("height")
                                      ? convertToFeetAndInches(
                                          condition.numberComparisionRange.min,
                                        )
                                      : condition.numberComparisionRange
                                          .min}{" "}
                                    and{" "}
                                    {[
                                      condition?.question?.numerictype,
                                      condition?.question?.constraints?.type,
                                    ].includes("height")
                                      ? convertToFeetAndInches(
                                          condition.numberComparisionRange.max,
                                        )
                                      : condition.numberComparisionRange.max}
                                  </span>
                                ) : (
                                  <span>
                                    {
                                      numberConditions.find(
                                        (c) =>
                                          c.value ===
                                          condition.numberComparisionCondition,
                                      ).label
                                    }{" "}
                                    {[
                                      condition?.question?.numerictype,
                                      condition?.question?.constraints?.type,
                                    ].includes("height")
                                      ? convertToFeetAndInches(
                                          condition.numberComparision,
                                        )
                                      : condition.numberComparision}
                                  </span>
                                )
                              ) : (
                                <span>{condition.answer.label}</span>
                              ))}
                          </div>
                        ))
                      : rule.rules.map((rule, index) => (
                          <div key={index}>
                            <span>
                              {rule.ruleCondition == "and" ? (
                                <span>Match all</span>
                              ) : (
                                <span>Match any</span>
                              )}
                            </span>
                            {rule.conditions.map((condition, i) => (
                              <div key={i}>
                                <label className="font-medium capitalize leading-loose text-gray-500">
                                  Question {index + 1}:{" "}
                                </label>
                                <span>{condition.question.label}</span>
                                <label className="px-2 font-medium capitalize leading-loose text-gray-500">
                                  {toReadableCondition(
                                    condition.questionCondition,
                                  )}
                                </label>
                                {condition.questionCondition !==
                                  "notAnswered" &&
                                  (["number", "decimal"].includes(
                                    condition.question.type,
                                  ) ? (
                                    condition.numberComparisionCondition ===
                                    "between" ? (
                                      <span>
                                        between{" "}
                                        {[
                                          condition?.question?.numerictype,
                                          condition?.question?.constraints
                                            ?.type,
                                        ].includes("height")
                                          ? convertToFeetAndInches(
                                              condition.numberComparisionRange
                                                .min,
                                            )
                                          : condition.numberComparisionRange
                                              .min}{" "}
                                        and{" "}
                                        {[
                                          condition?.question?.numerictype,
                                          condition?.question?.constraints
                                            ?.type,
                                        ].includes("height")
                                          ? convertToFeetAndInches(
                                              condition.numberComparisionRange
                                                .max,
                                            )
                                          : condition.numberComparisionRange
                                              .max}
                                      </span>
                                    ) : (
                                      <span>
                                        {
                                          numberConditions.find(
                                            (c) =>
                                              c.value ===
                                              condition.numberComparisionCondition,
                                          ).label
                                        }{" "}
                                        {[
                                          condition?.question?.numerictype,
                                          condition?.question?.constraints
                                            ?.type,
                                        ].includes("height")
                                          ? convertToFeetAndInches(
                                              condition.numberComparision,
                                            )
                                          : condition.numberComparision}
                                      </span>
                                    )
                                  ) : (
                                    <span>{condition.answer.label}</span>
                                  ))}{" "}
                              </div>
                            ))}
                          </div>
                        ))}
                  </div>
                  <h3 className="mb-1 text-lg font-semibold">
                    {rule.type === "group" && "Group"} Rule Operator:
                  </h3>
                  <div>
                    {rule.type === "single" ? (
                      <div>
                        <span>
                          {rule.ruleCondition == "and" ? (
                            <span>Match all</span>
                          ) : (
                            <span>Match any</span>
                          )}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span>
                          {rule.operator == "and" ? (
                            <span>Match all</span>
                          ) : (
                            <span>Match any</span>
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {rule.action === "addCPT" && (
                <div>
                  <label className="mb-3 block text-lg font-semibold">
                    Billable Items To Add :
                  </label>
                  <div>
                    <DataTable value={rule.billableItems}>
                      <Column field="hcpcs" header="HCPCS" />
                      <Column
                        field="longDescription"
                        header="Long Description"
                        style={{ width: "20%" }}
                      />
                      <Column field="description" header="Description" />
                      <Column
                        field="shortDescription"
                        header="Short Description"
                      />

                      <Column
                        header="Attribute Type"
                        body={(rowData) => (
                          <div>
                            <span className="pr-3">
                              {rowData.attributeType === 1 && "None"}
                              {rowData.attributeType === 2 && "NDC"}
                              {rowData.attributeType === 3 && "Supply Id"}
                              {rowData.attributeType === 4 && "Provider Type"}
                            </span>
                            <span>/ {rowData.attributeTypeValue}</span>
                          </div>
                        )}
                      />

                      <Column
                        header="Modifiers"
                        body={(rowData) => (
                          <div>
                            <span className="pr-1">{rowData.mod1} | </span>
                            <span className="pr-1">{rowData.mod2} | </span>
                            <span className="pr-1">{rowData.mod3} | </span>
                            <span className="pr-1">{rowData.mod4} </span>
                          </div>
                        )}
                      />

                      <Column field="units" header="Units" />

                      <Column
                        header="charge Amount"
                        body={(rowData) => (
                          <div>
                            <span>${rowData.chargeAmount}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </div>
                </div>
              )}
              {rule.action === "removeCPT" && (
                <div>
                  <label className="mb-3 block text-lg font-semibold">
                    Billable Items To remove :
                  </label>
                  <div>
                    <DataTable value={rule.billableItems}>
                      <Column field="hcpcs" header="HCPCS" />
                      <Column
                        field="longDescription"
                        header="Long Description"
                        style={{ width: "20%" }}
                      />
                      <Column field="description" header="Description" />
                      <Column
                        field="shortDescription"
                        header="Short Description"
                      />
                      <Column
                        header="Attribute Type"
                        body={(rowData) => (
                          <div>
                            <span className="pr-3">
                              {rowData.attributeType === "1" && "None"}
                              {rowData.attributeType === "2" && "NDC"}
                              {rowData.attributeType === "3" && "Supply Id"}
                              {rowData.attributeType === "4" && "Provider Type"}
                            </span>

                            <span>/ {rowData.attributeTypeValue}</span>
                          </div>
                        )}
                      />
                      <Column
                        header="Modifiers"
                        body={(rowData) => (
                          <div>
                            <span className="pr-1">{rowData.mod1} | </span>
                            <span className="pr-1">{rowData.mod2} | </span>
                            <span className="pr-1">{rowData.mod3} | </span>
                            <span className="pr-1">{rowData.mod4} </span>
                          </div>
                        )}
                      />
                      <Column
                        header="charge Amount"
                        body={(rowData) => (
                          <div>
                            <span>${rowData.chargeAmount}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </div>
                </div>
              )}
              {rule.action === "removeAllExcept" && (
                <div>
                  <label className="mb-3 block text-lg font-semibold">
                    Billable Items To Keep :
                  </label>
                  <div>
                    <DataTable value={rule.billableItems}>
                      <Column field="hcpcs" header="HCPCS" />
                      <Column
                        field="longDescription"
                        header="Long Description"
                        style={{ width: "20%" }}
                      />
                      <Column field="description" header="Description" />
                      <Column
                        field="shortDescription"
                        header="Short Description"
                      />
                      <Column
                        header="Attribute Type"
                        body={(rowData) => (
                          <div>
                            <span className="pr-3">
                              {rowData.attributeType === "1" && "None"}
                              {rowData.attributeType === "2" && "NDC"}
                              {rowData.attributeType === "3" && "Supply Id"}
                              {rowData.attributeType === "4" && "Provider Type"}
                            </span>

                            <span>/ {rowData.attributeTypeValue}</span>
                          </div>
                        )}
                      />
                      <Column
                        header="Modifiers"
                        body={(rowData) => (
                          <div>
                            <span className="pr-1">{rowData.mod1} | </span>
                            <span className="pr-1">{rowData.mod2} | </span>
                            <span className="pr-1">{rowData.mod3} | </span>
                            <span className="pr-1">{rowData.mod4} </span>
                          </div>
                        )}
                      />
                      <Column
                        header="charge Amount"
                        body={(rowData) => (
                          <div>
                            <span>${rowData.chargeAmount}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </div>
                </div>
              )}
              {rule.action === "removeMatchingPattern" && (
                <div>
                  <label className="mb-3 block text-lg font-semibold">
                    Billable Items To Remove:
                  </label>
                  <div>
                    <DataTable value={rule.billableItems}>
                      <Column field="hcpcs" header="HCPCS" />
                      <Column
                        field="longDescription"
                        header="Long Description"
                        style={{ width: "20%" }}
                      />
                      <Column field="description" header="Description" />
                      <Column
                        field="shortDescription"
                        header="Short Description"
                      />
                      <Column
                        header="Attribute Type"
                        body={(rowData) => (
                          <div>
                            <span className="pr-3">
                              {rowData.attributeType === "1" && "None"}
                              {rowData.attributeType === "2" && "NDC"}
                              {rowData.attributeType === "3" && "Supply Id"}
                              {rowData.attributeType === "4" && "Provider Type"}
                            </span>

                            <span>/ {rowData.attributeTypeValue}</span>
                          </div>
                        )}
                      />
                      <Column
                        header="Modifiers"
                        body={(rowData) => (
                          <div>
                            <span className="pr-1">{rowData.mod1} | </span>
                            <span className="pr-1">{rowData.mod2} | </span>
                            <span className="pr-1">{rowData.mod3} | </span>
                            <span className="pr-1">{rowData.mod4} </span>
                          </div>
                        )}
                      />
                      <Column
                        header="charge Amount"
                        body={(rowData) => (
                          <div>
                            <span>${rowData.chargeAmount}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </div>
                </div>
              )}

              {(rule.action === "AddUnitsToExisting" ||
                rule.action === "removeUnitsFromExisting") && (
                <div>
                  <label className="mb-3 block text-lg font-semibold">
                    Billable Items:
                  </label>
                  <div>
                    <DataTable
                      value={rule.defaultUnitsChange.filter(
                        (item) => item.changeInUnit,
                      )}
                    >
                      <Column field="hcpcs" header="HCPCS" />
                      <Column
                        field="longDescription"
                        header="Long Description"
                        style={{ width: "20%" }}
                      />
                      <Column field="description" header="Description" />
                      <Column
                        field="shortDescription"
                        header="Short Description"
                      />
                      <Column
                        header="Attribute Type"
                        body={(rowData) => (
                          <div>
                            <span className="pr-3">
                              {rowData.attributeType === 1 && "None"}
                              {rowData.attributeType === 2 && "NDC"}
                              {rowData.attributeType === 3 && "Supply Id"}
                              {rowData.attributeType === 4 && "Provider Type"}
                            </span>
                            <span>/ {rowData.attributeTypeValue}</span>
                          </div>
                        )}
                      />
                      <Column
                        header="Modifiers"
                        body={(rowData) => (
                          <div>
                            <span className="pr-1">{rowData.mod1} | </span>
                            <span className="pr-1">{rowData.mod2} | </span>
                            <span className="pr-1">{rowData.mod3} | </span>
                            <span className="pr-1">{rowData.mod4} </span>
                          </div>
                        )}
                      />
                      <Column field="changeInUnit" header="Unit Changed" />
                      <Column
                        header="Charge Amount"
                        body={(rowData) => <div>${rowData.chargeAmount}</div>}
                      />
                    </DataTable>
                  </div>
                </div>
              )}

              {(rule.action === "addLocations" ||
                rule.action === "removeLocations") && (
                <div className="py-2">
                  <DataTable
                    value={locationData}
                    className="overflow-hidden rounded-lg border"
                  >
                    <Column
                      header="Locations"
                      body={locationBodyTemplate}
                    ></Column>
                  </DataTable>
                </div>
              )}

              {rule.action === "abort" && (
                <div className="mt-4">
                  <div>
                    <Editor
                      value={rule.abortMessage}
                      headerTemplate="Abort Message"
                      readOnly
                      id="abort-message"
                      style={{ height: "120px" }}
                    />
                  </div>
                </div>
              )}
              {(rule.action === "addDocs" || rule.action === "removeDocs") && (
                <DataTable
                  className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
                  value={documentsData}
                  scrollHeight="360px"
                  scrollable
                  emptyMessage={
                    <p className="text-center">
                      Currently, there are no documents selected.
                    </p>
                  }
                >
                  <Column
                    field="documentTitle"
                    header="Title"
                    align="center"
                    alignHeader="center"
                    style={{
                      width: "30%",
                    }}
                  />
                  <Column
                    field="internalDescription"
                    header="Description"
                    align="center"
                    alignHeader="center"
                    style={{
                      width: "60%",
                    }}
                  />
                </DataTable>
              )}
              {rule.action === "swapCPT" && (
                <div className="mt-4 flex flex-col gap-5 rounded-lg border border-slate-100 p-5">
                  <SwappedBillableTable value={rule.billableItems[0]} />
                  <div className="flex items-center justify-center">
                    <i className="pi pi-arrow-down"></i>
                    <i className="pi pi-arrow-up"></i>
                  </div>
                  <SwappedBillableTable value={rule.billableItems[1]} />
                </div>
              )}

              <div className="flex justify-end">
                <Button
                  link
                  icon="pi pi-pencil text-purple-600 text-xl"
                  onClick={ediFunc}
                  className="p-button-danger"
                  tooltip="Edit rule"
                  tooltipOptions={{
                    showDelay: 1000,
                    hideDelay: 300,
                    position: "bottom",
                  }}
                />
                <Button
                  link
                  icon="pi pi-trash text-rose-600 text-xl"
                  onClick={handleDel}
                  className="p-button-danger"
                  tooltip="Delete rule"
                  tooltipOptions={{
                    showDelay: 1000,
                    hideDelay: 300,
                    position: "bottom",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
}

export default RenderRule;
