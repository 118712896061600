import { useRef, useState } from "react";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { MicrosoftLogin } from "react-microsoft-login";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import { trimValidation } from "../../utils/helpers";
import { MSAuthButton } from "../../components/formElements";
import {
  useAuthStore,
  useExternalLogin,
  useRefreshTokenStore,
  useRegisterFormStore,
  useStoreMslMicrosoft,
  useTokenStore,
} from "../../store/store";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const { setIsCompletedRegister } = useExternalLogin();
  const defaultValues = {
    email: "",
    password: "",
    checked: false,
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });
  const toast = useRef(null);
  const navigate = useNavigate();
  const setUser = useAuthStore((state) => state.setUser);
  const { setUserLoginId } = useAuthStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { setToken } = useTokenStore();
  const setFormData = useRegisterFormStore((state) => state.setFormData);
  // handle password eye
  const [passwordEye, setPasswordEye] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { onMsalInstanceChange, setIsMicrosoftLoggedIn } =
    useStoreMslMicrosoft();
  const handlePasswordClick = () => {
    setPasswordEye(!passwordEye);
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);

    try {
      if (!JSON.parse(localStorage.getItem("token")).state.token) {
        const response = await axios.post("/Authentication/login", formData);
        const data = response.data;

        if (data.isAuthenticated) {
          setToken(data.token);
          setRefreshToken(data.refreshToken);
          setUser(data);
          setUserLoginId(data.userId);
          setErrorMessage(null);
        }
      } else {
        navigate(`/chooseEntity/`, {
          replace: true,
        });
      }
    } catch (error) {
      if (error?.response?.data?.message === "Email needs to be confirmed") {
        setFormData(formData);
        navigate("/verifyEmail", {
          state: { email: error.response.data.email },
          replace: true,
        });
      } else {
        setErrorMessage(error.response.data.message);
        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);
      }
    }

    setIsLoading(false);
  };

  function getAccessTokenFromSessionStorage() {
    const staticAccessKey = "login.windows.net-accesstoken";
    let accessToken = null;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.includes(staticAccessKey)) {
        accessToken = sessionStorage.getItem(key);
        break;
      }
    }
    return accessToken;
  }

  function getIdTokenFromSessionStorage() {
    const staticIdKey = "login.windows.net-idtoken";
    let idToken = null;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.includes(staticIdKey)) {
        idToken = sessionStorage.getItem(key);
        break;
      }
    }
    return idToken;
  }

  const authHandler = async (err, data, msal) => {
    const accessTokenData = getAccessTokenFromSessionStorage();
    const idTokenData = getIdTokenFromSessionStorage();
    const accessData = JSON.parse(accessTokenData);
    const accessToken = accessData?.secret;
    const idData = JSON.parse(idTokenData);
    const idToken = idData?.secret;

    if (!err && data) {
      onMsalInstanceChange(msal);
      setIsMicrosoftLoggedIn(true);
    }
    const microsoftData = {
      provider: "MICROSOFT",
      idToken: idToken,
      accessToken: accessToken,
    };

    try {
      axios
        .post("/Authentication/ExternalLogin", microsoftData)
        .then((response) => {
          setToken(response.data.token);
          setRefreshToken(response.data.refreshToken);
          setIsCompletedRegister(response.data.isCompletedRegister);
          if (
            response.data.isNew === true ||
            response.data.isCompletedRegister === false
          ) {
            navigate("/completeRegistration", {
              replace: true,
            });
          } else {
            navigate("/chooseEntity", {
              replace: true,
            });
          }
        });
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          error.response.data.message ||
          "Something went wrong. Please try again later",
        life: 2000,
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="mb-9 flex items-center justify-center">
        <div className="Xl:w-1/3 mb-2 mt-10 w-11/12 rounded-3xl border border-solid border-light-purple bg-light-text p-5 md:mb-0 md:w-6/12 md:px-10 md:py-5 lg:w-1/3 2xl:w-1/3">
          <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-dark-purple">
            login in to MedX{" "}
          </h4>
          <div className="mb-14 mt-8">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                Email<span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <span className="p-input-icon-left w-full">
                <i className="pi pi-user" />
                <InputText
                  placeholder="Email Address"
                  className="w-full pb-2 pt-2"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid Email Address",
                    },
                  })}
                />
              </span>
              {errors.email && (
                <p className="text-red-500">{errors.email.message}</p>
              )}
              <div className="mt-4">
                <label>
                  Password<span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <span className="p-input-icon-left p-input-icon-right w-full">
                  <i className="pi pi-lock" />
                  <InputText
                    placeholder="Password (8 or more characters)"
                    className="w-full pb-2 pt-2"
                    name="password"
                    type={!passwordEye ? "password" : "text"}
                    {...register("password", {
                      required: "Password is required",
                      validate: (value) => trimValidation(value, "Password"),
                    })}
                  />
                  {!passwordEye ? (
                    <i
                      className="pi pi-eye-slash"
                      onClick={handlePasswordClick}
                    />
                  ) : (
                    <i className="pi pi-eye" onClick={handlePasswordClick} />
                  )}
                </span>
                {errors.password && (
                  <p className="mt-1 text-red-500">{errors.password.message}</p>
                )}

                {errorMessage && (
                  <Message
                    severity="error"
                    text={errorMessage + ". Please try again!"}
                    className="mt-9 w-full font-bold"
                  />
                )}
              </div>

              <div className="mt-8 flex flex-row justify-between">
                <div className="flex gap-1">
                  <Controller
                    name="checked"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Checkbox
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          onChange={(e) => field.onChange(e.checked)}
                          className="pt-1"
                        />
                      </>
                    )}
                  />
                  <span>Keep me logged in</span>
                </div>
                <div
                  onClick={() => navigate("/forgetPassword")}
                  className="text-right"
                >
                  <span className="cursor-pointer text-light-purple">
                    Forgot password?
                  </span>
                </div>
              </div>

              {isLoading ? (
                <div className="text-center">
                  <ProgressSpinner
                    strokeWidth={3}
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
              ) : (
                <Button
                  type="submit"
                  className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                >
                  Login
                </Button>
              )}
              <div className="mb-5 mt-6 grid grid-cols-3 items-center text-gray-400">
                <hr className="border-gray-400" />
                <p className="text-center text-sm">OR</p>
                <hr className="border-gray-400" />
              </div>
            </form>

            <div className="flex justify-center">
              <MicrosoftLogin
                clientId="736fdbcc-003f-4597-a7e9-9ef7947c56c9"
                authCallback={authHandler}
              >
                <MSAuthButton label="Sign in with Microsoft" />
              </MicrosoftLogin>
            </div>
            <div className="mt-4 flex justify-center">
              <GoogleOAuthProvider clientId="1094559917244-mc9dmm3g6jj1b0d5ejs6pooaevng1235.apps.googleusercontent.com">
                <GoogleLogin
                  text="signin_with"
                  onSuccess={async (credentialResponse) => {
                    const googleData = {
                      provider: "GOOGLE",
                      accessToken: null,
                      idToken: credentialResponse.credential,
                    };
                    try {
                      const response = await axios.post(
                        "/Authentication/ExternalLogin",
                        googleData,
                      );
                      setToken(response.data.token);
                      setRefreshToken(response.data.refreshToken);
                      setIsCompletedRegister(response.data.isCompletedRegister);

                      if (
                        response.data.isNew === true ||
                        response.data.isCompletedRegister === false
                      ) {
                        navigate("/completeRegistration", { replace: true });
                      } else {
                        navigate("/chooseEntity", { replace: true });
                      }
                    } catch (error) {
                      console.error(error);
                      toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail:
                          error.response?.data?.message ||
                          "Something went wrong. Please try again later",
                        life: 2000,
                      });
                    }
                  }}
                  onError={() => {}}
                  context="signup"
                />
              </GoogleOAuthProvider>
            </div>

            <div className="mt-5 text-gray-400">
              <p className="text-center text-sm capitalize">
                don't have an account?
              </p>
            </div>
            <div className="text-center">
              <Link to={"/registration"}>
                <Button className="mt-3 justify-center rounded-full bg-transparent py-2.5 font-inter font-semibold capitalize text-dark-purple">
                  sign up
                </Button>
              </Link>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <span className="mr-3 text-center text-sm text-gray-600 md:text-left">
              © <span id="currentYear">{new Date().getFullYear()}</span>{" "}
              MedX™. All Rights Reserved.
            </span>
            <a href="/legal" target="_blank">
              <span className="text-center text-sm text-gray-600 hover:underline md:text-left">
                Legal Notices and Terms
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
