import { Navigate, Outlet } from "react-router-dom";
import useTokenData from "../hooks/useTokenData";
import { useExpireSystemAdmin, useSwitchOrdering } from "../store/store";

export const AdminAuthGaurd = () => {
  const { SystemAdministrator } = useTokenData();
  const { setIsOrderingMode } = useSwitchOrdering();
  const { isExpireAdmin } = useExpireSystemAdmin();

  if (SystemAdministrator !== "SystemAdministrator") {
    setIsOrderingMode(false);
    return <Navigate to={"/Dashboard/"} replace />;
  } else if (isExpireAdmin == true) {
    return <Navigate to={"/admin/expired"} replace />;
  }
  return <Outlet />;
};
