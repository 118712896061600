import { useEffect, useRef, useState } from "react";

import { driver } from "driver.js";
import { unstable_usePrompt, useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { SplitButton } from "primereact/splitbutton";
import { TabPanel, TabView } from "primereact/tabview";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import RuleForm from "../components/RuleForm";
import RenderRule from "../components/RenderRule";
import TestService from "../components/TestService";
import useAxios from "../../../../../hooks/useAxios";
import QuestionsWrapper from "../components/Questions";
import QuestionMaker from "../components/QuestionMaker";
import useTokenData from "../../../../../hooks/useTokenData";
import ServiceChecklist from "../components/ServiceChecklist";
import ServiceInfoTemplate from "../components/ServiceInfoTemplate";
import { useBlockNavigation, useLayoutToast } from "../../../../../store/store";

import "driver.js/dist/driver.css";

const CreateNewService = () => {
  const [rules, setRules] = useState([]);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [editQuestion, setEditQuestion] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState();
  const [mainBillables, setMainBillables] = useState([]);
  const [mainLocation, setMainLocation] = useState([]);
  const [createNewLast, setCreateNewLast] = useState(false);
  const [questionType, setQuestionType] = useState();
  const [testServiceVisible, setTestServiceVisible] = useState(false);
  const serviceFormRef = useRef(null);
  const defaultAnswer = [{ label: "" }, { label: "" }];
  const driverObj = driver({
    showProgress: true,
    allowClose: true,
    steps: [
      {
        element: "#serviceName",
        popover: {
          title: "Enter service name",
          description:
            "Enter service name to distinguish the service from other services.",
          side: "top",
          align: "start",
        },
      },
      {
        element: "#serviceDescription",
        popover: {
          title: "Enter service description",
          description: "Enter service description.",
          side: "top",
          align: "start",
        },
      },
      {
        element: "#newLocation",
        popover: {
          title: "Add new Location",
          description:
            "Add new location to be able to set them as default or add and remove from them according to rules.",
          side: "top",
          align: "start",
        },
      },
      {
        element: "#serviceLocation",
        popover: {
          title: "Enter service Location",
          description:
            "Enter locations where the service would be availbe with all it's default billable items,but we can add and substract from them too with rules.",
          side: "top",
          align: "start",
        },
      },
      {
        element: "#serviceBillables",
        popover: {
          title: "Create Billable Item",
          description:
            "Create billable items to use as default billable items or as rule dependant billable items.",
          side: "top",
          align: "start",
        },
      },
      {
        element: "#serviceDefaultBillables",
        popover: {
          title: "Add Billable Items",
          description:
            "here we can add billable items as default for the service from the already created billable items",
          side: "top",
          align: "start",
        },
      },
    ],
  });
  function startTheMagicShow() {
    driverObj.drive();
  }
  const [formQuestions, setFormQuestions] = useState([
    {
      type: "multipleChoice",
      label: "Gender",
      options: [
        {
          label: "Male",
        },
        {
          label: "Female",
        },
        {
          label: "Unspecified",
        },
      ],
      show: false,
      conditions: [
        {
          question: {
            label: "",
          },
          answer: {
            label: "",
          },
          numberComparisionCondition: "equal",
          numberComparision: 0,
          numberComparisionRange: { min: 0, max: 0 },
        },
      ],
      required: true,
      order: 0,
    },
    {
      type: "number",
      label: "Age",
      conditions: [
        {
          question: {
            label: "",
          },
          answer: {
            label: "",
          },
          numberComparisionCondition: "equal",
          numberComparision: 0,
          numberComparisionRange: { min: 0, max: 0 },
        },
      ],
      required: true,
      order: 1,
    },
  ]);
  const [allQuestions, setAllQuestions] = useState([
    {
      type: "multipleChoice",
      label: "Gender",
      options: [
        {
          label: "Male",
        },
        {
          label: "Female",
        },
        {
          label: "Unspecified",
        },
      ],
    },
    {
      type: "number",
      label: "Age",
      conditions: [
        {
          question: {
            label: "",
          },
          answer: {
            label: "",
          },
          numberComparisionCondition: "equal",
          numberComparision: 0,
          numberComparisionRange: { min: 0, max: 0 },
        },
      ],
      required: true,
      order: 1,
    },
  ]);
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const toast = useRef(null);
  const { layoutToast } = useLayoutToast();
  const [questionandAnswerToEdit, setQuestionandAnswerToEdit] = useState({});
  const [availableLocations, setAvailableLocations] = useState();
  const [availableAnswers, setAvailableAnswers] = useState();
  const [allRules, setAllRules] = useState([]);
  const [currentEditingRules, setCurrentEditingRules] = useState([]);
  const [minAndMaxFees, setMinAndMaxFees] = useState([]);
  const [questionChosen, setQuestionChosen] = useState();
  const [answerChosen, setAnswerChosen] = useState();
  const [errors, setErrors] = useState({});
  const [isEditingRule, setIsEditingRule] = useState(false);
  const [generalEditInfo, setGeneralEditInfo] = useState();
  const [editedQuestionIndex, setEditedQuestionIndex] = useState();
  const [selectedCptCodes, setSelectedCptCodes] = useState([]);
  const [activeRules, setActiveRules] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [quickAddRule, setQuickAddRule] = useState(false);
  const [isCreatingService, setIsCreatingService] = useState(false);
  const [isSavingProgress, setIsSavingProgress] = useState(false);
  const [locationRefetch, setLocationRefetch] = useState(false);
  const [serviceInfoFormData, setServiceInfoFormData] = useState();
  const [formInteracted, setFormInteracted] = useState(false);
  const handleServiceInfoFormChange = (newFormData) => {
    setServiceInfoFormData({
      name: newFormData[0].trim(),
      description: newFormData[1].trim(),
      serviceType: newFormData[2],
      locations: newFormData[3],
      billables: newFormData[4],
      serviceDocumentsIds: newFormData[5],
      sendCheckInPass: newFormData[6],
    });
  };

  const isGeneralInfoValid = () => {
    setFormInteracted(true);
    let newErrors = {};
    if (formQuestions && !formQuestions.length) {
      newErrors = {
        ...newErrors,
        formQuestions: { message: "Please add at least one question." },
      };
    }
    if (
      !serviceInfoFormData ||
      !serviceInfoFormData ||
      !serviceInfoFormData.name
    ) {
      newErrors = {
        ...newErrors,
        serviceName: {
          message: "Please enter a name for the service.",
        },
      };
    }

    if (!serviceInfoFormData || !serviceInfoFormData.description) {
      newErrors = {
        ...newErrors,
        serviceDescription: {
          message: "Please enter a description for the service.",
        },
      };
    }

    if (!serviceInfoFormData || serviceInfoFormData.locations.length === 0) {
      newErrors = {
        ...newErrors,
        location: { message: "Please select at least one location." },
      };
    }

    if (!serviceInfoFormData || selectedCptCodes.length === 0) {
      newErrors = {
        ...newErrors,
        billables: {
          message: "Please select at least one billable item.",
        },
      };
    }
    setErrorsFields(newErrors);
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      serviceFormRef.current.scrollIntoView({ behavior: "smooth" });
      return false;
    }
    return true;
  };

  const [errorsFields, setErrorsFields] = useState({});
  const handleUpdateErrors = (fieldName, fieldErrors) => {
    setErrorsFields((prevErrors) => ({
      ...prevErrors,
      [fieldName]: fieldErrors[fieldName]?.message || "",
    }));
  };

  const dragEnded = (param) => {
    const { source, destination } = param;
    if (!destination) return;
    let _arr = [...formQuestions];
    //extracting the source item from the list
    const _item = _arr.splice(source.index, 1)[0];
    //inserting it at the destination index.
    _arr.splice(destination.index, 0, _item);
    setFormQuestions(_arr);
  };

  const getBillables = async () => {
    const response = await http.put(
      `/BillableItems/GetAllBillableItems/${entityId}`,
      [],
    );
    setMainBillables(response.data);
  };
  const getLocation = async () => {
    try {
      const res = await http.get(
        `/Locations/GetLocationsByEntityId/${entityId}`,
      );
      setMainLocation(res.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };
  useEffect(() => {
    getBillables();
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Wanna leave?";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    getLocation();
  }, [locationRefetch]);

  const handleAddToForm = (question) => {
    const newQuestion = {
      ...question,
      order: formQuestions.length + 1,
    };
    setFormQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    setAllQuestions([...formQuestions, newQuestion]);
  };

  const { blockNavigation, setBlockNavigation } = useBlockNavigation();
  useEffect(() => {
    return () => {
      setBlockNavigation(true);
    };
  }, [setBlockNavigation]);
  unstable_usePrompt({
    message:
      "Are you sure you want to leave before saving? All your data will be discarded",
    when: ({ currentLocation, nextLocation }) =>
      blockNavigation &&
      !isCreatingService &&
      currentLocation.pathname !== nextLocation.pathname,
  });

  const sendData = async (dataToBeSent, save) => {
    const { serviceDocumentsIds, ...rest } = serviceInfoFormData;

    const rulesLocations = allRules
      .filter((rule) => rule.action === "addLocations")
      .flatMap((rule) => rule.locations);

    const defaultBillablesIds = serviceInfoFormData?.billables?.map(
      (billable) => billable.id,
    );
    const rulesBillablesIds = allRules
      .filter((rule) => rule.action === "addCPT" || rule.action === "swapCPT")
      .flatMap((rule) =>
        rule.action === "swapCPT" ? rule.billableItems[1] : rule.billableItems,
      )
      .map((billable) => billable.id);

    if (save) setIsSavingProgress(true);
    try {
      const response = await http.post(
        `/Services/CreateServiceWizard/${entityId}`,
        {
          ...rest,
          billables: defaultBillablesIds,
          rulesBillables: rulesBillablesIds,
          rulesLocations: rulesLocations,
          serviceJson: dataToBeSent,
          isTemplate: false,
        },
      );
      if (save) {
        layoutToast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Progress Saved Successfully",
          life: 4000,
        });
        setIsSavingProgress(false);
        navigate(`/Service/MyServices/EditService/${response.data}/${false}`, {
          replace: true,
        });
        return;
      }
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Service Created Successfully",
        life: 4000,
      });
      setTimeout(() => {
        navigate("/service/MyServices", { replace: true });
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 4000,
      });
    }
  };
  const handleSubmit = (save = false) => {
    if (!isGeneralInfoValid()) return;

    const sendingJSON = JSON.stringify({
      generalInfo: {
        ...serviceInfoFormData,
      },
      questions: formQuestions,
      services: allRules,
    });

    if (formQuestions.length <= 2 && !save) {
      confirmDialog({
        message:
          "Would you like to add conditional statements to customize service availability, location filtering, and shopping cart items?",
        header: "Customize Service Options",
        icon: "pi pi-cog",
        defaultFocus: "accept",
        acceptLabel: "Add Customizations",
        rejectLabel: "Save and Proceed",
        accept: () => {},
        reject: () => {
          setIsCreatingService(true);
          sendData(sendingJSON);
        },
        style: { width: "800px" },
        draggable: false,
      });
    } else {
      setIsCreatingService(true);
      sendData(sendingJSON, save);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRules = Array.from(allRules);
    const [removed] = updatedRules.splice(result.source.index, 1);
    updatedRules.splice(result.destination.index, 0, removed);
    setAllRules(updatedRules);
  };
  const startRule = () => {
    confirmDialog({
      message: "Do you wish to add a new rule?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => {
        setActiveIndex(1);
        setQuickAddRule(true);
      },
      reject: () => {},
    });
  };
  const serviceFormData = {
    generalInfo: {
      ...serviceInfoFormData,
    },
    questions: formQuestions,
    services: allRules,
  };
  const sendingTestJSON = JSON.stringify(serviceFormData);

  const handleCancelEditingQuestion = () => {
    if (editQuestion) {
      setEditQuestion(false);
      setQuestionToEdit();
    } else {
      setCreateNewLast(false);
    }
  };

  useEffect(() => {
    activeIndex && handleCancelEditingQuestion();
  }, [activeIndex]);

  const handleCancelServiceCreation = () => {
    confirmDialog({
      message: "Are you sure you want to leave? ALL unsaved data will be LOST.",
      header: "Confirm Exit",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, Leave",
      rejectLabel: "No, Stay",
      accept: () => {
        setBlockNavigation(false);
        navigate(-1);
      },
      reject: () => {},
      style: { width: "500px" },
      draggable: false,
    });
  };

  return (
    <div className="relative grid h-full grid-cols-12 gap-4">
      <div
        className="col-span-12 flex flex-col md:col-span-9"
        style={{
          height: "calc(100vh - 5rem)",
        }}
      >
        <Toast ref={toast} />
        <ConfirmDialog />
        <div className="relative overflow-y-auto">
          <div ref={serviceFormRef}>
            <ServiceInfoTemplate
              driverObj={driverObj}
              billables={mainBillables}
              location={mainLocation}
              onFormDataChange={handleServiceInfoFormChange}
              setAvailableLocations={setAvailableLocations}
              availableLocations={availableLocations}
              setErrorsForm={setErrors}
              errorsForm={errors}
              setGeneralEditInfo={setGeneralEditInfo}
              setFormQuestions={setFormQuestions}
              setAllQuestions={setAllQuestions}
              allRules={allRules}
              setAllRules={setAllRules}
              editValues={generalEditInfo}
              rules={rules}
              setRules={setRules}
              selectedCptCodes={selectedCptCodes}
              setSelectedCptCodes={setSelectedCptCodes}
              onUpdateErrors={handleUpdateErrors}
              errorsFields={errorsFields}
              setErrorsFields={setErrorsFields}
              locationRefetch={locationRefetch}
              setLocationRefetch={setLocationRefetch}
              formInteracted={formInteracted}
            />
          </div>

          {/* <Divider /> */}
          <div className="mt-3 overflow-hidden rounded-2xl">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel header="Question Bank" contentClassName="">
                <div className="flex">
                  <div className="mb-4 w-full rounded-lg border bg-slate-50 p-5">
                    <div className="relative">
                      <DragDropContext onDragEnd={dragEnded}>
                        <Droppable droppableId="questions-wrapper">
                          {(provided) => (
                            <QuestionsWrapper
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {formQuestions.length > 2 ? (
                                formQuestions.map((question, index) => {
                                  if (index > 1) {
                                    return (
                                      <Draggable
                                        draggableId={`question-${index}`}
                                        index={index}
                                        key={index}
                                        isDragDisabled={
                                          questionToEdit?.index &&
                                          !createNewLast
                                        }
                                      >
                                        {(_provided) => (
                                          <div
                                            key={index}
                                            ref={_provided.innerRef}
                                            {...(!questionToEdit?.index &&
                                            !createNewLast
                                              ? _provided.draggableProps
                                              : {})}
                                            {...(!questionToEdit?.index &&
                                            !createNewLast
                                              ? _provided.dragHandleProps
                                              : {})}
                                          >
                                            {
                                              <div key={index}>
                                                <QuestionMaker
                                                  createNewLast={createNewLast}
                                                  startRule={startRule}
                                                  editedQuestionIndex={
                                                    editedQuestionIndex
                                                  }
                                                  questionType={questionType}
                                                  defaultAnswer={defaultAnswer}
                                                  setEditedQuestionIndex={
                                                    setEditedQuestionIndex
                                                  }
                                                  currentIndex={index}
                                                  setQuestionToEdit={
                                                    setQuestionToEdit
                                                  }
                                                  isEditingRule={isEditingRule}
                                                  setIsEditingRule={
                                                    setIsEditingRule
                                                  }
                                                  allRules={allRules}
                                                  setAllRules={setAllRules}
                                                  currentEditingRules={
                                                    currentEditingRules
                                                  }
                                                  setCurrentEditingRules={
                                                    setCurrentEditingRules
                                                  }
                                                  initialQuestionState={
                                                    question
                                                  }
                                                  editQuestion={editQuestion}
                                                  setEditQuestion={
                                                    setEditQuestion
                                                  }
                                                  formQuestions={formQuestions}
                                                  onAddToForm={handleAddToForm}
                                                  setFormQuestions={
                                                    setFormQuestions
                                                  }
                                                  setRules={setRules}
                                                  setVisible={setVisible}
                                                  rules={rules}
                                                  activeRules={activeRules}
                                                  setActiveRules={
                                                    setActiveRules
                                                  }
                                                  questionToEdit={
                                                    questionToEdit
                                                  }
                                                  allQuestions={allQuestions}
                                                  questionChosen={
                                                    questionChosen
                                                  }
                                                  setQuestionChosen={
                                                    setQuestionChosen
                                                  }
                                                  answerChosen={answerChosen}
                                                  setAnswerChosen={
                                                    setAnswerChosen
                                                  }
                                                  setAllQuestions={
                                                    setAllQuestions
                                                  }
                                                  availableAnswers={
                                                    availableAnswers
                                                  }
                                                  setAvailableAnswers={
                                                    setAvailableAnswers
                                                  }
                                                  setQuestionandAnswerToEdit={
                                                    setQuestionandAnswerToEdit
                                                  }
                                                />
                                              </div>
                                            }
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }
                                })
                              ) : (
                                <p className="text-center font-semibold text-gray-700">
                                  Looks like you haven't added any questions
                                  yet. Click 'Add New Question' to get started
                                </p>
                              )}
                              {provided.placeholder}
                            </QuestionsWrapper>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </div>
                {!questionToEdit?.index &&
                  (createNewLast ? (
                    <QuestionMaker
                      createNewLast={createNewLast}
                      startRule={startRule}
                      questionType={questionType}
                      defaultAnswer={defaultAnswer}
                      editedQuestionIndex={editedQuestionIndex}
                      setEditedQuestionIndex={setEditedQuestionIndex}
                      isEditingRule={isEditingRule}
                      setIsEditingRule={setIsEditingRule}
                      allRules={allRules}
                      setAllRules={setAllRules}
                      currentEditingRules={currentEditingRules}
                      setCurrentEditingRules={setCurrentEditingRules}
                      editQuestion={editQuestion}
                      setEditQuestion={setEditQuestion}
                      formQuestions={formQuestions}
                      onAddToForm={handleAddToForm}
                      setFormQuestions={setFormQuestions}
                      setRules={setRules}
                      setVisible={setVisible}
                      rules={rules}
                      activeRules={activeRules}
                      setActiveRules={setActiveRules}
                      questionToEdit={questionToEdit}
                      allQuestions={allQuestions}
                      questionChosen={questionChosen}
                      setQuestionChosen={setQuestionChosen}
                      answerChosen={answerChosen}
                      setAnswerChosen={setAnswerChosen}
                      setAllQuestions={setAllQuestions}
                      availableAnswers={availableAnswers}
                      setAvailableAnswers={setAvailableAnswers}
                      setQuestionandAnswerToEdit={setQuestionandAnswerToEdit}
                      createNew={createNewLast}
                      afterFinishFunction={() => {
                        setCreateNewLast(false);
                      }}
                      setCreateNew={setCreateNewLast}
                    />
                  ) : (
                    <div className="my-10 flex justify-center">
                      <SplitButton
                        model={[
                          {
                            label: "Multiple Choice (Checkbox)",
                            command: () => {
                              setQuestionType("multipleChoice");
                              setCreateNewLast(true);
                            },
                          },
                          {
                            label: "Single Choice (Dropdown)",
                            command: () => {
                              setQuestionType("dropDown");
                              setCreateNewLast(true);
                            },
                          },
                          {
                            label: "Number (whole)",
                            command: () => {
                              setQuestionType("number");
                              setCreateNewLast(true);
                            },
                          },
                          {
                            label: "Number (decimal)",
                            command: () => {
                              setQuestionType("decimal");
                              setCreateNewLast(true);
                            },
                          },
                          {
                            label: "Yes or No",
                            command: () => {
                              setQuestionType("yesNo");
                              setCreateNewLast(true);
                            },
                          },
                        ]}
                        severity="success"
                        label={"Add new question"}
                        icon="pi pi-plus"
                        onClick={() => {
                          setCreateNewLast(true);
                        }}
                        className="mx-2 my-2 whitespace-nowrap"
                      />
                    </div>
                  ))}
              </TabPanel>
              <TabPanel header="Conditions">
                <RuleForm
                  quickAddRule={quickAddRule}
                  setQuickAddRule={setQuickAddRule}
                  setQuestionandAnswerToEdit={setQuestionandAnswerToEdit}
                  toast={toast}
                  activeRules={structuredClone(activeRules)}
                  setActiveRules={setActiveRules}
                  isEditingRule={isEditingRule}
                  editedQuestionIndex={editedQuestionIndex}
                  setEditedQuestionIndex={setEditedQuestionIndex}
                  setIsEditingRule={setIsEditingRule}
                  rules={structuredClone(rules)}
                  currentEditingRules={structuredClone(currentEditingRules)}
                  setCurrentEditingRules={setCurrentEditingRules}
                  setRules={setRules}
                  formQuestions={formQuestions}
                  billables={structuredClone(mainBillables)}
                  setBillables={setMainBillables}
                  mainLocation={structuredClone(mainLocation)}
                  allQuestions={structuredClone(formQuestions)}
                  questionChosen={structuredClone(questionChosen)}
                  setQuestionChosen={setQuestionChosen}
                  answerChosen={answerChosen}
                  setAnswerChosen={setAnswerChosen}
                  availableAnswers={availableAnswers}
                  setAvailableAnswers={setAvailableAnswers}
                  serviceInfoFormData={serviceInfoFormData}
                  allRules={structuredClone(allRules)}
                  setAllRules={setAllRules}
                  setVisible={setVisible}
                  visible={visible}
                  setMinAndMaxFees={setMinAndMaxFees}
                  minAndMaxFees={minAndMaxFees}
                  availableLocations={availableLocations}
                  questionandAnswerToEdit={questionandAnswerToEdit}
                  selectedCptCodes={structuredClone(selectedCptCodes)}
                  setSelectedCptCodes={setSelectedCptCodes}
                  serviceDocumentsIds={serviceInfoFormData?.serviceDocumentsIds}
                />
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="rules">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {allRules.map((rule, index) => (
                          <RenderRule
                            key={index}
                            rule={rule}
                            index={index}
                            setAllRules={setAllRules}
                            allRules={allRules}
                            ediFunc={() => {
                              setIsEditingRule(true);
                              setCurrentEditingRules(index);
                              setVisible(true);
                              setRules([allRules[index]]);
                            }}
                            locations={mainLocation}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </TabPanel>
            </TabView>
          </div>
        </div>
        <Dialog
          blockScroll
          draggable={false}
          visible={testServiceVisible}
          onHide={() => setTestServiceVisible(false)}
          header="Test Service"
          className="max-h-[95vh] w-11/12 md:w-[80vw]"
        >
          <TestService
            setVisible={setTestServiceVisible}
            serviceInfo={sendingTestJSON}
          />
        </Dialog>
        <div className="mb-8 flex w-full flex-col gap-2 border border-gray-300 bg-slate-50 px-3 py-2 md:gap-5">
          <div className="flex items-center justify-center gap-2 md:hidden">
            <Button
              id="test-service-button"
              outlined
              label="Test Service"
              icon="pi pi-exclamation-triangle"
              className="md:p-auto w-1/2 p-3 md:w-auto"
              onClick={() => {
                if (!isGeneralInfoValid()) return;
                setTestServiceVisible(true);
              }}
            />
            <Button
              id="save-progress-button"
              severity="info"
              label="Save Progress"
              loading={isSavingProgress}
              className="md:p-auto w-1/2 p-3 md:w-auto"
              icon="pi pi-save"
              onClick={() => handleSubmit(true)}
            />
          </div>
          <div className="flex items-center justify-center gap-2 md:justify-end">
            <Button
              id="cancel-service-button"
              severity="danger"
              label="Cancel"
              className="md:p-auto w-1/2 p-3 md:w-auto"
              icon="pi pi-times"
              onClick={handleCancelServiceCreation}
            />
            <Button
              id="create-service-button"
              severity="success"
              label="Create Service"
              className="md:p-auto w-1/2 p-3 md:w-auto"
              icon="pi pi-check"
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </div>
      <div className="sticky top-0 hidden h-2/3 gap-4 md:col-span-3 md:flex md:flex-col">
        <div className="max-h-[40rem] overflow-y-auto">
          <ServiceChecklist formData={serviceFormData} />
        </div>
        <Button
          id="save-progress-button"
          severity="info"
          label="Save Progress"
          loading={isSavingProgress}
          className="md:p-auto w-1/2 p-3 md:w-auto"
          icon="pi pi-save"
          onClick={() => handleSubmit(true)}
        />
        <Button
          id="test-service-button"
          outlined
          label="Test Service"
          icon="pi pi-exclamation-triangle"
          className="md:p-auto w-1/2 p-3 md:w-auto"
          onClick={() => {
            if (!isGeneralInfoValid()) return;
            setTestServiceVisible(true);
          }}
        />
      </div>
    </div>
  );
};
export default CreateNewService;
