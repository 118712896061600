import { Link, useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import ReusableSidebar from "./ReusableSidebar";

import Footer from "./Footer";
import { useCurrentEntity, useSwitchOrdering } from "../store/store";
import useTokenData from "../hooks/useTokenData";
import { useLayoutEffect } from "react";
import { ServiceStatusLabel } from "../components/banners";

const Layout = ({ children }) => {
  const location = useLocation();
  const { currentEntity } = useCurrentEntity();
  const { SystemAdministrator } = useTokenData();
  const { isOrderingMode } = useSwitchOrdering();
  const hideFooter = /^\/service\/myservices\/.+/.test(
    location.pathname.toLowerCase(),
  );

  useLayoutEffect(() => {
    const container = document.getElementById("protected-routes");
    container && container.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <>
      <div className="nav-bar">
        <NavBar />
      </div>
      <div className="w-100 flex rounded-tr-sm pt-20">
        <div className="hidden lg:block">
          <ReusableSidebar />
        </div>
        <div
          id="protected-routes"
          className="flex h-[calc(100vh-5rem)] w-full flex-col overflow-auto p-5 py-0"
        >
          <div className="mb-2 flex">
            <Link
              to={
                SystemAdministrator === "SystemAdministrator"
                  ? "admin/ourPractices"
                  : !isOrderingMode
                    ? "/Dashboard"
                    : "ordering/FindServices"
              }
            >
              <div className="flex items-center gap-2 font-bold text-[#343A40] hover:cursor-pointer hover:shadow-[0_2px_0_0_#343A40]">
                <div>
                  <i className="pi pi-home"></i>
                </div>
                <div>Home</div>
              </div>
            </Link>
          </div>
          {currentEntity?.isBannerRead === false && <ServiceStatusLabel />}
          {children}
          {!hideFooter && (
            <div className="mt-auto pt-6">
              <Footer />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Layout;
