import { confirmDialog } from "primereact/confirmdialog";

export const showPinConfirmation = (handleAccept, handleReject) => {
  confirmDialog({
    message: (
      <div className="mb-4">
        <p className="font-semibold">
          As an official ordering provider, an Authorization PIN is required to
          authenticate your decision (approval or denial) for submitted orders.{" "}
          <span className="mt-2 block">
            Would you like to go ahead and setup this Authorization PIN now?
          </span>
        </p>
        <p className="mt-4 text-sm text-gray-500">
          You can always change your PIN under your profile in
          <span className="font-semibold">
            {" "}
            Settings &gt; Security Settings{" "}
          </span>
          in the future. You will be prompted for this Authorization PIN during
          the order approval process.
        </p>
      </div>
    ),
    header: (
      <div className="flex items-center space-x-2 pl-4">
        <i className="pi pi-exclamation-triangle text-xl font-semibold text-yellow-500"></i>
        <span className="text-lg capitalize">Set PIN?</span>
      </div>
    ),
    acceptLabel: "Set PIN",
    rejectLabel: "Skip & proceed",
    style: { width: "40rem" },
    accept: handleAccept,
    reject: handleReject,
    draggable: false,
  });
};
