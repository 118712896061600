import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import { useExpireSystemAdmin, useTokenStore } from "../../store/store";
import useTokenData from "../../hooks/useTokenData";
import useAxios from "../../hooks/useAxios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
const AdminSignalR = () => {
  const { logOut } = useAxios();
  const [visible, setVisible] = useState(false);
  const { userId } = useTokenData();
  const { token } = useTokenStore();
  const { setIsExpireAdmin } = useExpireSystemAdmin();
  let hubConnection = useRef(null);

  const navigate = useNavigate();

  const startConnection = () => {
    hubConnection.current = new signalR.HubConnectionBuilder()
      .withUrl("/notify", {
        accessTokenFactory: () => token,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    hubConnection.current
      .start()
      .then()
      .catch((err) => console.error("Error while starting connection: " + err));
  };

  useEffect(() => {
    startConnection();

    hubConnection.current.on("NotifyUserTracking", (newData) => {
      if (userId === newData.notifyTo) {
        if (newData.action === "LogOut") {
          setIsExpireAdmin(true);
          navigate("/admin/expired", {
            replace: true,
          });
        }
      }
    });

    return () => {
      if (hubConnection.current) {
        hubConnection.current.off("NotifyUserTracking");
        hubConnection.current.stop();
      }
    };
  }, [token]);

  return (
    <Dialog
      visible={visible}
      draggable={false}
      modal
      style={{ width: "50rem" }}
      onHide={() => {
        if (!visible) return;
        setVisible(false);
        logOut();
      }}
      className="w-11/12 border-l border-none p-0 outline-0 md:w-[30vw]"
      pt={{
        header: {
          style: {
            background:
              "linear-gradient(to right,  rgb(108 100 236), rgb(163 212 238))",
          },
        },
        content: {
          style: {
            background:
              "linear-gradient(to right,  rgb(108 100 236), rgb(163 212 238))",
          },
        },
      }}
      closable={false}
    >
      <div className="flex w-full flex-col items-center justify-center text-white">
        <p
          className="text-title"
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          You have been logged out because your account has been disabled by
          another system admin. You can no longer access the system.
        </p>

        <Button
          label="Ok"
          icon="pi pi-check"
          onClick={() => {
            setVisible(false);
            logOut();
          }}
          className="hover:text-purple border-none text-xl font-bold transition-all duration-300 ease-in-out hover:scale-90"
          style={{
            background: "#735bf6",
            padding: "10px 20px",
            borderRadius: "8px",
          }}
          autoFocus
        />
      </div>
    </Dialog>
  );
};

export default AdminSignalR;
