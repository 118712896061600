import React, { useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import useImpersonating from "../../hooks/useImpersonating";
import {
  useRefreshTokenStore,
  useSwitchOrdering,
  useTokenStore,
} from "../../store/store";

function DialogCreatePin({
  fromTap,
  onComplete,
  showDialogCreatePin,
  setShowDialogCreatePin = () => {},
}) {
  const { http } = useAxios();
  const { HasPin } = useTokenData();
  const { isSystemAdminImpersonating } = useImpersonating();
  const toast = useRef();
  const navigate = useNavigate();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { isOrderingMode } = useSwitchOrdering();
  const [pinEye, setPinEye] = useState(false);
  const [confirmPinEye, setConfirmPinEye] = useState(false);
  const handlePinClick = () => setPinEye(!pinEye);
  const handleConfirmPinClick = () => setConfirmPinEye(!confirmPinEye);

  const currentRefreshToken = JSON.parse(localStorage.getItem("refreshToken"))
    .state.refreshToken;
  const defaultValues = {
    pinCode: "",
    confirmPinCode: "",
    refreshToken: currentRefreshToken,
  };

  const {
    handleSubmit,
    reset,
    control,
    trigger,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues,
  });
  const onSubmit = async (formData) => {
    try {
      const res = await http.put(`/Users/SetUserPinCode`, formData);
      setToken(res.data.token);
      setRefreshToken(res.data.refreshToken);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 4000,
      });
      reset();
    } catch (error) {
      console.error("Error creating order:", error);
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: error.response.data.message,
        life: 4000,
      });
    } finally {
      if (fromTap) {
        const path = isOrderingMode
          ? "/ordering/settings/"
          : "/rendering/settings/";
        navigate(path);
      } else {
        setShowDialogCreatePin(false);
        onComplete && onComplete();
      }
    }
  };
  const handleCancel = () => {
    const path = isOrderingMode
      ? "/ordering/settings/"
      : "/rendering/settings/";
    navigate(path);
  };

  return (
    <>
      <Toast ref={toast} />

      <form
        className="w-full"
        onSubmit={handleSubmit(onSubmit)}
        id="createPinForm"
      >
        <div className="flex w-full items-center justify-center py-4 sm:px-8">
          <div className="w-full rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
            <div>
              <h4 className="mb-4 text-center text-3xl font-bold sm:text-left">
                Set Provider Authentication PIN
              </h4>
              <p className="mb-3 text-center text-gray-700 sm:text-left">
                Set your PIN to ensure only you can place and accept orders,
                even if a staff member initiates them
              </p>
            </div>

            <div className="pt-7">
              <label className="block pb-2 text-base capitalize leading-loose">
                New PIN <span className="text-red-600">*</span>
              </label>
              <Controller
                name="pinCode"
                control={control}
                rules={{
                  required: "PIN field is required",
                  pattern: {
                    value: /^\d{4,12}$/,
                    message: "PIN must be between 4 and 12 digits",
                  },
                }}
                render={({ field }) => (
                  <>
                    <span
                      className={`p-input-icon-left p-input-icon-right ${fromTap ? "w-6/12" : "w-full"}`}
                    >
                      <i className="pi pi-lock" />
                      <InputText
                        placeholder="New PIN"
                        maxLength={12}
                        keyfilter="num"
                        className="w-full pb-2 pt-2"
                        name="pinCode"
                        disabled={isSystemAdminImpersonating}
                        type={!pinEye ? "password" : "text"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger("confirmPinCode");
                        }}
                      />
                      {!pinEye ? (
                        <i
                          className="pi pi-eye-slash cursor-pointer"
                          onClick={handlePinClick}
                        />
                      ) : (
                        <i
                          className="pi pi-eye cursor-pointer"
                          onClick={handlePinClick}
                        />
                      )}
                    </span>
                  </>
                )}
              />
              {errors.pinCode && (
                <p className="text-red-500">{errors.pinCode.message}</p>
              )}
            </div>
            <div className="pt-4">
              <label className="block pb-2 text-base capitalize leading-loose">
                Confirm PIN <span className="text-red-600">*</span>
              </label>
              <Controller
                name="confirmPinCode"
                control={control}
                rules={{
                  validate: (value) => {
                    return value === getValues("pinCode")
                      ? true
                      : "PIN does not match";
                  },
                }}
                render={({ field }) => (
                  <span
                    className={`p-input-icon-left p-input-icon-right ${fromTap ? "w-6/12" : "w-full"}`}
                  >
                    <i className="pi pi-lock" />
                    <InputText
                      placeholder="Confirm PIN"
                      maxLength={12}
                      className="w-full pb-2 pt-2"
                      name="confirmPinCode"
                      disabled={isSystemAdminImpersonating}
                      keyfilter="num"
                      type={!confirmPinEye ? "password" : "text"}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger("confirmPinCode");
                      }}
                    />
                    {!confirmPinEye ? (
                      <i
                        className="pi pi-eye-slash cursor-pointer"
                        onClick={handleConfirmPinClick}
                      />
                    ) : (
                      <i
                        className="pi pi-eye cursor-pointer"
                        onClick={handleConfirmPinClick}
                      />
                    )}
                  </span>
                )}
              />

              {errors.confirmPinCode && (
                <p className="text-red-500">{errors.confirmPinCode.message}</p>
              )}
            </div>
            <div className="mt-7 flex flex-col-reverse items-center justify-center sm:justify-end md:flex-row md:gap-5">
              {fromTap && (
                <div className="w-full md:w-auto">
                  <Button
                    onClick={handleCancel}
                    disabled={isSystemAdminImpersonating || isSubmitting}
                    type="button"
                    severity="danger"
                    label="Cancel"
                  ></Button>
                </div>
              )}
              <div className="w-full items-end justify-end md:w-auto">
                <Button
                  loading={isSubmitting}
                  disabled={isSystemAdminImpersonating}
                  label={`${HasPin === "True" ? "Update PIN" : "Set PIN"}`}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default DialogCreatePin;
