import { Skeleton } from "primereact/skeleton";

export default function OrderSummarySkeleton() {
  return (
    <div className="flex w-full flex-col gap-4">
      {/* Header section skeleton */}
      <div className="flex flex-col items-center gap-2">
        <Skeleton width="75%" height="2rem" className="mb-2" />
        <Skeleton width="60%" height="1.5rem" />
        <Skeleton width="40%" height="1.5rem" />
      </div>

      {/* Receipt details skeleton */}
      <div className="rounded-lg border p-4">
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <Skeleton width="30%" height="1.5rem" />
            <Skeleton width="20%" height="1.5rem" />
          </div>
          <div className="flex justify-between">
            <Skeleton width="40%" height="1.5rem" />
            <Skeleton width="25%" height="1.5rem" />
          </div>
          <div className="flex justify-between">
            <Skeleton width="35%" height="1.5rem" />
            <Skeleton width="20%" height="1.5rem" />
          </div>
        </div>
      </div>

      {/* Order summary section skeleton */}
      <div>
        <div className="mb-2 flex items-center gap-2">
          <Skeleton shape="circle" size="2rem" />
          <Skeleton width="30%" height="2rem" />
        </div>
        <div className="rounded-lg border p-4">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-8">
              <Skeleton width="100%" height="4rem" />
            </div>
            <div className="col-span-4">
              <Skeleton width="100%" height="2.5rem" />
            </div>
          </div>
        </div>
      </div>

      <p className="mt-2 text-center font-semibold text-gray-500">
        Please wait while we confirm your payment...
      </p>
    </div>
  );
}
