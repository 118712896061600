import { useEffect, useRef, useState } from "react";

import { utils, writeFile } from "xlsx";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../../hooks/useAxios";
import BankAccInfo from "../components/BankAccInfo";
import useHandleResize from "../../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../../components/Table";

function RemitsComplete() {
  const { http } = useAxios();
  const toast = useRef(null);
  const location = useLocation();
  const rowData = location.state.rowData;
  const [selectedRows, setSelectedRows] = useState([]);
  const [refetch, setRefetch] = useState();
  const [visible, setVisible] = useState(false);
  const [bankAccData, setBankAccData] = useState(null);
  const [bankAccDialog, setBankAccDialog] = useState(false);
  const [bankAccDataStatus, setBankAccDataStatus] = useState("idle");
  const { isMobile } = useHandleResize();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      paymentDate: new Date(),
      referenceNumber: "",
    },
  });

  useEffect(() => {
    setValue("paymentDate", new Date());
  }, [setValue]);
  const orderIds = !isMobile ? selectedRows.map((row) => row.id) : selectedRows;
  const Transaction = async (data) => {
    try {
      const response = await http.post(`/Payments/completeOrderTransaction`, {
        orderIds: orderIds,
        transactionId: data.referenceNumber,
        completedTransactionDate: data.paymentDate,
      });
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      setRefetch((e) => !refetch);
      setVisible(false);
      setValue("referenceNumber", "");
      setValue("paymentDate", new Date());
      setSelectedRows([]);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.data?.message,
        life: 4000,
      });
      setValue("referenceNumber", "");
      setValue("paymentDate", new Date());
      console.error("Error completing transaction:", error);
    }
  };
  const getBankAccData = async () => {
    setBankAccDataStatus("loading");
    try {
      const response = await http.get(
        `/BankAccountInfos/GetBankAccounts/${rowData?.entityId}`,
      );
      setBankAccData(response.data);
      if (response.data.length) {
        setBankAccDataStatus("success");
      } else {
        setBankAccDataStatus("no-data");
      }
    } catch (error) {
      setBankAccDataStatus("error");
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => {
          setVisible(false);
          setValue("referenceNumber", "");
          setValue("paymentDate", new Date());
        }}
        className="p-button-text"
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        onClick={handleSubmit(Transaction)}
        disabled={!selectedRows.length}
        autoFocus
      />
    </div>
  );

  const rightToolbarTemplate = () => (
    <div className="flex flex-wrap gap-2">
      <Button onClick={exportToExcel}>Export to Excel</Button>
      <Button
        label="Mark Completed"
        icon="pi pi-check"
        severity="success"
        disabled={!selectedRows.length || !selectedRows}
        onClick={() => {
          setVisible(true);
        }}
      />
    </div>
  );

  const exportToExcel = async () => {
    const response = await http.get(
      `/Entities/EntityResultedOrders/${rowData?.entityId}`,
    );

    const data = response.data;

    const exportData = data.map((item) => {
      const row = {
        "ORDER NUMBER": item.orderNumber,
        "ORDER TOTAL CHARGED": item.orderTotalCharged,
        "SERVICE FEES": item.serviceFees,
        "SERVICE CHARGE": item.serviceCharged,
        "CAPACITY CHARGE": item.practiceChargeCapacity,
      };

      return row;
    });

    const ws = utils.json_to_sheet(exportData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Transactions");
    writeFile(wb, "Outstanding_Transactions.xlsx");
  };

  const orderTotalChargedTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold">
        {" "}
        ${rowData.orderTotalCharged}
      </h4>
    </>
  );

  const serviceChargedTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold">
        {" "}
        ${rowData.serviceCharged}
      </h4>
    </>
  );

  const serviceFeesTemplate = (rowData) => (
    <div className="flex items-center gap-3">
      <h4 className="font-inter text-lg font-bold">{rowData.serviceFees}%</h4>
      <h4 className="font-inter text-sm text-gray-500">
        (Cap: <strong>${rowData.practiceChargeCapacity}</strong>)
      </h4>
    </div>
  );

  const columns = [
    { name: "Order Number", value: "orderNumber" },
    {
      name: "Order Total Charged",
      value: "orderTotalCharged",
      template: orderTotalChargedTemplate,
    },
    {
      name: "Service Charge",
      value: "serviceCharged",
      template: serviceChargedTemplate,
    },
    {
      name: "Service Fees",
      value: "serviceFees",
      template: serviceFeesTemplate,
    },
  ];

  return (
    <>
      <div className="rounded-t-lg">
        <Toast ref={toast} />
        <Dialog
          draggable={false}
          blockScroll
          header="Enter Time Reference Number"
          visible={visible}
          className="w-full max-w-md sm:max-w-lg lg:max-w-xl"
          onHide={() => {
            setVisible(false);
            setValue("referenceNumber", "");
            setValue("paymentDate", new Date());
          }}
          footer={footerContent}
        >
          <form onSubmit={handleSubmit(Transaction)}>
            <label className="text-sm">
              The default selected date is set to today's date.
            </label>
            <Controller
              name="paymentDate"
              control={control}
              rules={{
                required: "Payment Date is required",
                validate: (value) => {
                  const today = new Date();
                  const minDate = new Date(today);
                  minDate.setFullYear(today.getFullYear() - 1);
                  const maxDate = new Date(today);
                  maxDate.setDate(today.getDate() + 7);
                  if (value < minDate || value > maxDate) {
                    return "Payment Date must be within the last year or up to 1 week in the future";
                  }
                },
              }}
              render={({ field }) => (
                <Calendar
                  {...field}
                  dateFormat="mm/dd/yy"
                  minDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 1),
                    )
                  }
                  maxDate={
                    new Date(new Date().setDate(new Date().getDate() + 7))
                  }
                  showTime
                  inline
                  hourFormat="12"
                  pt={{
                    day: { style: { padding: "0", margin: "0" } },
                    header: { style: { padding: "0", margin: "0" } },
                    table: { style: { padding: "0", margin: "0" } },
                    timePicker: { style: { padding: "0", margin: "0" } },
                    hourPicker: { style: { padding: "0", margin: "0" } },
                    panel: { style: { padding: "0", margin: "0" } },
                    minute: {
                      style: { padding: "0", margin: "0", fontSize: "16px" },
                    },
                    hour: {
                      style: { padding: "0", margin: "0", fontSize: "16px" },
                    },
                    ampm: {
                      style: { padding: "0", margin: "0", fontSize: "16px" },
                    },
                  }}
                  className="mb-2 w-full p-0"
                />
              )}
            />
            {errors.paymentDate && (
              <p className="text-red-600">{errors.paymentDate.message}</p>
            )}

            <div className="mt-2">
              <span>Reference Number</span>
              <Controller
                name="referenceNumber"
                control={control}
                rules={{ required: "Reference Number is required" }}
                render={({ field }) => (
                  <InputText {...field} className="w-full" />
                )}
              />
              {errors.referenceNumber && (
                <p className="text-red-600">{errors.referenceNumber.message}</p>
              )}
            </div>
          </form>
        </Dialog>

        <Dialog
          blockScroll
          draggable={false}
          header="Bank Accounts Information"
          visible={bankAccDialog}
          className="w-[50vw]"
          onHide={() => setBankAccDialog(false)}
        >
          {bankAccDataStatus === "loading" ? (
            <div className="flex items-center justify-center">
              <ProgressSpinner />
            </div>
          ) : bankAccDataStatus === "no-data" ? (
            <p>No bank accounts have been set up for this practice.</p>
          ) : bankAccDataStatus === "error" ? (
            <Message
              content="Something went wrong while getting bank account information."
              severity="error"
              className="w-full"
            />
          ) : (
            bankAccDataStatus === "success" && (
              <BankAccInfo bankAccData={bankAccData} />
            )
          )}
        </Dialog>
        <div className="flex items-center justify-between rounded-t-lg bg-white p-5">
          <div className="flex gap-2">
            <span className="font-inter font-semibold">Practice name:</span>
            <span className="font-inter">{rowData.practiceName}</span>
          </div>
          <Button
            onClick={() => {
              getBankAccData();
              setBankAccDialog(true);
            }}
            label="Bank Account Info"
            text
            severity="info"
          />
        </div>
        <ReusableDataList
          disableSearch={true}
          fromFront={true}
          RemitsCheck={true}
          dataLink={`/Entities/EntityResultedOrders/${rowData.entityId}`}
          columns={columns}
          refetch={refetch}
          rightToolbarTemplate={rightToolbarTemplate}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </>
  );
}

export default RemitsComplete;
