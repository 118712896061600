import { useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import { TaxonomyTree } from "../../../components/formElements";
import useRefreshTokenHook from "../../../hooks/useRefreshTokenHook";
import GroupPracticeIcon from "../../../iconComponents/GroupPracticeIcon";
import { useRefreshTokenStore, useTokenStore } from "../../../store/store";

function GroupPracticeFinishStep() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const { IdentifierId, impersonatedBy } = useTokenData();
  const { http } = useAxios();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  // NPI expand form
  const [NPIList, setNPIList] = useState([{ npiNumber: "" }]);
  const AddNPI = () => {
    setNPIList([...NPIList, { npiNumber: "" }]);
  };
  const { refresh } = useRefreshTokenHook();
  //get tree taxonomy from api
  const [selectedOptionsList, setSelectedOptionsList] = useState([]);
  const [selectedChooseList, setSelectedChooseList] = useState([]);
  const { entityId } = useTokenData();

  const deleteNPI = (index) => {
    setNPIList((oldNpi) => {
      const newNpi = [...oldNpi];
      newNpi.splice(index, 1);

      setValue("npIs", newNpi);
      return newNpi;
    });
  };

  const defaultValues = {
    identifierId: IdentifierId,
    npIs: NPIList,
    assignTaxonomies: selectedChooseList,
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: defaultValues });

  const onSubmit = async (formData) => {
    const modifiedOptionsList = selectedChooseList.map((option) => {
      const { label, ...rest } = option;
      return rest;
    });
    formData.assignTaxonomies = modifiedOptionsList;
    setIsLoading(true);
    const currentRefreshToken = JSON.parse(localStorage.getItem("refreshToken"))
      .state.refreshToken;
    try {
      await http.post("/Identifications/AssignDueDiligence", formData);
      const refreshTokenResponse = await refresh.post(
        `/Authentication/RefreshToken/${entityId}`,
        {
          refreshToken: currentRefreshToken,
          impersonatedBy,
        },
      );
      setToken(refreshTokenResponse.data.token);
      setRefreshToken(refreshTokenResponse.data.refreshToken);
      reset();
      navigate(`/welcome`, {
        replace: true,
        state: { fromSetup: true },
      });
    } catch (error) {
      if (error.message === "Please select at least one taxonomy as primary") {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Please select at least one taxonomy as primary",
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response.data.message,
          life: 4000,
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex items-center justify-center">
        <div className="mt-10 w-full rounded-3xl border bg-light-text p-10 sm:w-full md:w-3/4">
          <div className="grid grid-cols-2 items-center md:grid-cols-6">
            <div className="col-span-1 md:col-span-2">
              <div className="flex h-24 w-24 items-center justify-center overflow-hidden rounded-full border-2 border-dashed border-gray-300 bg-slate-100">
                <GroupPracticeIcon />
              </div>
            </div>
            <div className="col-span-1 md:col-span-4">
              <h4 className="font-inter text-lg font-extrabold capitalize text-dark-purple">
                Set up my Group practice
              </h4>
            </div>
          </div>
          <div className="mt-9">
            <p>
              Please populate all the NPIs associated with your practice below,
              along with the taxonomies.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-container">
              {NPIList.map((NPIs, index) => (
                <div key={index} className="mt-4">
                  <label
                    htmlFor="NPI"
                    className="font-Poppins text-base capitalize leading-loose text-gray-500"
                  >
                    group NPI
                  </label>
                  <div className="grid grid-cols-6">
                    <div className="col-span-5">
                      <span className="p-input-icon-right w-full">
                        {index !== 0 ? (
                          <i
                            className="pi pi-times cursor-pointer"
                            style={{ color: "red" }}
                            key={`${index}BTN`}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteNPI(index);
                            }}
                          />
                        ) : null}
                        <InputText
                          key={`${index}input`}
                          id="npiNumber"
                          placeholder="0000000000"
                          maxLength={10}
                          keyfilter={/^\d+$/}
                          className="w-full"
                          {...register(`npIs[${index}].npiNumber`, {
                            required: index === 0 ? "NPI is required" : false,
                            pattern: {
                              value: /^\d{10}$/,
                              message: "NPI must be exactly 10 digits",
                            },
                          })}
                          onChange={(e) => {
                            setNPIList((oldNpi) => {
                              const newNpi = [...oldNpi];
                              newNpi[index].npiNumber = e.target.value;
                              return newNpi;
                            });
                          }}
                        />
                      </span>
                    </div>
                    <div className="col-span-1 mt-2 text-center"></div>
                    {errors.npIs &&
                      errors.npIs[index] &&
                      errors.npIs[index].npiNumber && (
                        <p className="col-span-6 mt-1 text-red-500">
                          {errors.npIs[index].npiNumber.message}
                        </p>
                      )}
                  </div>
                </div>
              ))}
              <div className="mb-3">
                <Button
                  link
                  className="pl-1 text-base font-semibold capitalize"
                  onClick={(e) => {
                    e.preventDefault();
                    AddNPI();
                  }}
                >
                  <span className="font-sm pb-1 pr-1 text-2xl"> + </span> add
                  NPI
                </Button>
              </div>
              <label className="block font-Poppins text-base capitalize leading-loose text-gray-500">
                group taxonomy <span className="text-red-500">*</span>
              </label>
              <span className="text-sm">
                Click the arrow icon to select a taxonomy.
              </span>
              <TaxonomyTree
                selectedChooseList={selectedChooseList}
                selectedOptionsList={selectedOptionsList}
                setSelectedChooseList={setSelectedChooseList}
                setSelectedOptionsList={setSelectedOptionsList}
              />
              {!selectedChooseList[0] && (
                <p className="mt-1 text-left text-red-500">
                  Select taxonomy & at least one is primary
                </p>
              )}
              <div className="mt-5 text-left">
                {isLoading ? (
                  <div className="text-center">
                    <ProgressSpinner
                      strokeWidth={3}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-row-reverse gap-8">
                      <Button
                        disabled={!selectedChooseList[0] || !NPIList[0]}
                        type="submit"
                        className="mt-9 justify-end rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default GroupPracticeFinishStep;
