import { useEffect, useState } from "react";

import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

const keysMap = {
  testdev: process.env.REACT_APP_STRIPE_TEST,
  staging: process.env.REACT_APP_STRIPE_STAGING,
  prod: process.env.REACT_APP_STRIPE_PROD,
};
const stripeKey = keysMap[process.env.REACT_APP_ENVIRONMENT];

const stripePromise = loadStripe(stripeKey);

export default function EmbeddedStripeDialog({
  paymentLinkId,
  handleComplete,
}) {
  const [clientOptions, setClientOptions] = useState({
    clientSecret: "",
  });

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const fetchClientSecret = async () => {
    try {
      const res = await axios.get(`Payments/checkout/${paymentLinkId}`);
      setClientOptions({ clientSecret: res.data });
    } catch (error) {
      console.error("Error fetching client secret:", error);
    }
  };
  return (
    <div>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{ ...clientOptions, onComplete: handleComplete }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
