import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

import TestService from "./TestService";
import useAxios from "../../../../../hooks/useAxios";
import { ManageServiceCard } from "./ManageServiceCard";
import { useChecklist } from "../../../../../store/store";
import useTokenData from "../../../../../hooks/useTokenData";
import useHandleResize from "../../../../../hooks/useHandleResize";
import AroundTheWorld from "../../../../../iconComponents/AroundTheWorld";
import { ServiceLocationsSkeleton } from "../../../../../components/skeletons/ServiceLocationsSkeleton";

export const ManageQuestionsTemplate = ({
  refetch,
  setRefetch,
  isPublished,
}) => {
  const [serviceData, setServiceData] = useState();
  const [serviceLocations, setServiceLocations] = useState();
  const { entityId, IsEntityApproved } = useTokenData();
  const { http } = useAxios();
  const [dataStatus, setDataStatus] = useState("idle");
  const [locationStatus, setLocationStatus] = useState("idle");
  const [publishStatus, setPublishStatus] = useState("idle");
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [serviceId, setServiceId] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All Services");
  const [publish, setPublish] = useState(false);
  const [isPublishedAll, setIsPublishedAll] = useState(false);

  const toast = useRef(null);
  const navigate = useNavigate();
  const { isMobile } = useHandleResize();
  const { setShouldUpdateProgress } = useChecklist();

  const getServiceData = async (
    url = `/Entities/GetEntityServices/${entityId}`,
  ) => {
    setLoading(true);
    setDataStatus("loading");
    try {
      const response = await http.get(url);
      const services = response.data.filter((item) => !item.isTemplate);
      const sortedServices = services.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setServiceData(sortedServices);
      if (response.data[0]) {
        setDataStatus("success");
      } else {
        setDataStatus("no-data");
      }
      setLoading(false);
    } catch (error) {
      setDataStatus("error");
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    let currentTab = null;
    if (selectedFilter === "true") {
      currentTab = true;
    } else if (selectedFilter === "false") {
      currentTab = false;
    }
    let url = `/Entities/GetEntityServices/${entityId}`;
    if (selectedFilter !== "All Services") {
      url = `/Entities/GetEntityServices/${entityId}?Filters=IsPublished==${currentTab}`;
    }
    getServiceData(url);
    return () => {
      setLocationStatus("");
    };
  }, [publish, selectedFilter]);

  const handleDisabledAction = (action) => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: `${action} is disabled until your practice is approved`,
      life: 3000,
    });
  };

  const handlePublishAllClick = async () => {
    const servicesIds = serviceData.map((item) => item.id);
    if (servicesIds[0]) {
      confirmDialog({
        message: `Are you sure you want to ${
          selectedFilter === "true" ? "unpublish" : "publish"
        } all services?`,
        icon: "pi pi-exclamation-triangle",
        position: "center",
        accept: async () => {
          try {
            let currentTab = null;
            if (selectedFilter === "true") {
              currentTab = true;
            } else if (selectedFilter === "false") {
              currentTab = false;
            }
            await http.put("/Services/BulkUpdate", {
              servicesIds: servicesIds,
              isPublished: !currentTab,
            });
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: `All services ${
                isPublishedAll ? "unpublished" : "published"
              } successfully`,
              life: 3000,
            });
            setIsPublishedAll(!isPublishedAll);
            setPublish(!publish);
            //setRefetch(!refetch);
            setShouldUpdateProgress(true);
          } catch (error) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail:
                error.message || "Something went wrong. Please try again later",
              life: 3000,
            });
          }
        },
      });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `No services to be ${
          isPublishedAll ? "unpublished" : "published"
        }`,
        life: 3000,
      });
    }
  };

  const handleDelete = async (id) => {
    confirmDialog({
      message: `Are you sure you want to delete this service?`,
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        try {
          await http.delete(`/Services/${id}`);
          getServiceData();
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Service Deleted successfully",
            life: 3000,
          });
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              error.response.data.message ||
              "Something went wrong. Please try again later",
            life: 3000,
          });
        }
      },
    });
  };

  const handleCardClick = async (id) => {
    setLocationStatus("loading");
    try {
      const response = await http.get(`/Services/GetServiceLocations/${id}`);
      setServiceLocations(response.data);
      if (response.data[0]) {
        setLocationStatus("success");
      } else {
        setLocationStatus("no-data");
      }
    } catch (error) {
      setLocationStatus("error");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Something went wrong. Please try again later",
        life: 3000,
      });
    }
  };

  const handlePublish = async (service) => {
    confirmDialog({
      message: `Are you sure you want to ${
        service.isPublished ? "unpublish" : "publish"
      } this service?`,
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        setPublishStatus("loading");
        // publish or unpublish service based on this condition
        try {
          http
            .put(`/Services/PublishOrUnPublish/${service.id}`, {
              isPublished: !service.isPublished,
            })
            .then(() => {
              setPublish(!publish);
            });

          setPublishStatus("success");

          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `Service ${service.isPublished ? "Unpublished" : "Published"} Successfully`,
            life: 3000,
          });
          setShouldUpdateProgress(true);
        } catch (error) {
          setPublishStatus("error");
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response.data.message,
            life: 3000,
          });
        }
      },
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        blockScroll
        draggable={false}
        header="Test Service"
        className="max-h-[95vh] w-11/12 md:w-[80vw]"
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
      >
        <TestService setVisible={setVisible} serviceId={serviceId} />
      </Dialog>
      {loading ? (
        <div className="w-30 flex h-[25rem] items-center justify-center">
          <ProgressSpinner className="w-30 h-30 m-auto flex items-center justify-center" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-4 md:max-w-xl md:flex-row md:items-center">
            <div>
              <Dropdown
                options={[
                  { name: "All Services", value: "All Services" },
                  { name: "Published Services", value: "true" },
                  { name: "Unpublished Services", value: "false" },
                ]}
                value={selectedFilter}
                optionLabel="name"
                className="h-[3rem] w-full md:w-[14rem]"
                onChange={(e) => {
                  setSelectedFilter(e.target.value);
                }}
              />
            </div>
            <div>
              {selectedFilter === "false" ? (
                <>
                  <Button
                    tooltip={
                      (IsEntityApproved === "Pending" ||
                        IsEntityApproved === "Denied" ||
                        IsEntityApproved === "InComplete") &&
                      "Publishing is disabled until your practice is approved"
                    }
                    tooltipOptions={{
                      showDelay: 700,
                      hideDelay: 300,
                      position: "bottom",
                    }}
                    className="grid w-full md:w-auto"
                    severity={
                      IsEntityApproved === "Pending" ||
                      IsEntityApproved === "Denied" ||
                      IsEntityApproved === "InComplete"
                        ? "secondary"
                        : "success"
                    }
                    outlined
                    onClick={() => {
                      if (
                        IsEntityApproved === "Pending" ||
                        IsEntityApproved === "Denied" ||
                        IsEntityApproved === "InComplete"
                      ) {
                        handleDisabledAction("Publishing");
                        return;
                      }
                      handlePublishAllClick();
                    }}
                    disabled={dataStatus !== "success"}
                  >
                    Publish All Services
                  </Button>
                </>
              ) : null}

              {selectedFilter === "true" ? (
                <>
                  {/* <p className="text-xl font-semibold text-[#1E293B]">Services</p> */}
                  <Button
                    onClick={() => {
                      if (
                        IsEntityApproved === "Pending" ||
                        IsEntityApproved === "Denied" ||
                        IsEntityApproved === "InComplete"
                      ) {
                        handleDisabledAction("Unpublishing");
                        return;
                      }
                      handlePublishAllClick();
                    }}
                    className="grid w-full md:w-auto md:text-[.88rem]"
                    tooltip={
                      (IsEntityApproved === "Pending" ||
                        IsEntityApproved === "Denied" ||
                        IsEntityApproved === "InComplete") &&
                      "Unpublishing is disabled until your practice is approved"
                    }
                    tooltipOptions={{
                      showDelay: 700,
                      hideDelay: 300,
                      position: "bottom",
                    }}
                    severity={
                      IsEntityApproved === "Pending" ||
                      IsEntityApproved === "Denied" ||
                      IsEntityApproved === "InComplete"
                        ? "secondary"
                        : "info"
                    }
                    outlined
                    disabled={dataStatus !== "success"}
                  >
                    Unpublish All Services
                  </Button>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex w-full flex-col overflow-y-auto lg:w-8/12">
              {dataStatus === "success" ? (
                serviceData.map((service, index) => (
                  <div key={index}>
                    <ManageServiceCard
                      key={index}
                      name={service.name}
                      description={service.description}
                      price={`Starts from ~ $${service.minFees}`}
                      isPublished={service.isPublished}
                      onDeleteClick={() => handleDelete(service.id)}
                      onPublishClick={() => handlePublish(service)}
                      onCradClick={() => handleCardClick(service.id)}
                      onEditClick={() => {
                        navigate(
                          `/Service/MyServices/EditService/${service.id}/${false}`,
                        );
                      }}
                      onTestClick={() => {
                        setVisible(true);
                        setServiceId(service.id);
                      }}
                      onCopyClick={() => {
                        navigate(
                          `/Service/MyServices/EditService/${service.id}/${false}`,
                          { state: { isCopy: true } },
                        );
                      }}
                      tooltip={index === 0 ? true : false}
                      isDisabled={publishStatus === "loading"}
                      toast={toast}
                      serviceType={service.serviceType}
                    />
                  </div>
                ))
              ) : (
                <p className="mt-4 rounded bg-white p-5 text-gray-400">
                  No results found
                </p>
              )}
            </div>

            <div className="hidden w-4/12 lg:flex">
              {!isMobile && (
                <div className="sticky top-0 mt-4 h-fit w-full rounded-md bg-white p-5 font-inter md:hidden lg:block">
                  <p className="mb-4 text-xl font-extrabold text-[#3D3877]">
                    Service Locations
                  </p>
                  <div className="max-h-[16rem] overflow-y-auto">
                    {locationStatus === "success" ? (
                      serviceLocations.map((location, i) => (
                        <div key={i} className="flex items-center gap-x-2">
                          <i className="pi pi-map-marker text-[#594FC4]" />
                          <p className="mt-2 p-2 text-justify font-inter text-sm font-normal text-[#434343]">{`${location.address.lineOne}. ${location.address.city}, ${location.address.state} ${location.address.zipCode}`}</p>
                        </div>
                      ))
                    ) : locationStatus === "no-data" ? (
                      <p className="rounded bg-white p-5 text-gray-400">
                        No results found
                      </p>
                    ) : locationStatus === "loading" ? (
                      <ServiceLocationsSkeleton />
                    ) : (
                      <div>
                        <p className="mb-4 text-justify font-semibold text-gray-400">
                          By clicking on a service card, the locations where the
                          service is available will be displayed here.
                        </p>
                        <div className="flex justify-center">
                          <AroundTheWorld />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
