import { useEffect, useState } from "react";

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

import ahsLogo from "../../images/ahs.svg";
import medxLogo from "../../images/Medx@2x.png";
//TODO: change key with the production key

const keysMap = {
  testdev: process.env.REACT_APP_STRIPE_TEST,
  staging: process.env.REACT_APP_STRIPE_STAGING,
  prod: process.env.REACT_APP_STRIPE_PROD,
};
const stripeKey = keysMap[process.env.REACT_APP_ENVIRONMENT];

const stripePromise = loadStripe(stripeKey);

export default function EmbeddedStripeCheckout() {
  const [clientOptions, setClientOptions] = useState({
    clientSecret: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("Id");

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const handleComplete = () => {
    navigate(`/return?status=complete&id=${id}`);
  };

  const fetchClientSecret = async () => {
    try {
      const res = await axios.get(`Payments/checkout/${id}`);
      setClientOptions({ clientSecret: res.data });
    } catch (error) {
      console.error("Error fetching client secret:", error);
    }
  };

  return (
    <div className="flex h-screen w-full flex-col items-center bg-white">
      <div className="flex items-center">
        <div className="w-16">
          <img src={medxLogo} alt="MedX logo" />
        </div>
      </div>
      <div
        id="checkout"
        className="h-[80%] w-11/12 overflow-y-auto rounded-xl border shadow-md sm:w-10/12 md:w-[70vw]"
      >
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ ...clientOptions, onComplete: handleComplete }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>

      <div className="flex flex-col p-2">
        <div className="text-xs font-semibold text-gray-500">Powered by:</div>

        <div className="w-56">
          <img src={ahsLogo} alt="ahs logo" />
        </div>
      </div>
    </div>
  );
}
