import { Card } from "primereact/card";

import { Section } from "../../../components/cards";
import medxLogo from "./../../../images/Medx@2x.png";

const TermsAndConditions = () => {
  return (
    <div className="m-auto my-10 min-h-screen w-9/12 rounded-xl bg-white px-7 py-8 leading-relaxed">
      <Card className="m-auto rounded-md border border-solid border-light-purple px-6 text-sm leading-relaxed">
        <img src={medxLogo} alt="medx-logo" className="m-auto w-44" />
        <h1 className="mb-6 bg-gradient-to-r from-[#2c3e50] to-[#3498db] bg-clip-text text-center text-3xl font-bold text-transparent">
          MedX Services Marketplace Terms of Service
        </h1>
        <p className="mb-8 text-center text-sm text-gray-600">
          Last Modified: July 07, 2024
        </p>

        <p className="mb-4 font-bold uppercase">
          PLEASE READ THESE MARKETPLACE TERMS OF USE CAREFULLY. BY ACCESSING OR
          USING OUR WEBSITE, APP, OR SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE
          READ, UNDERSTOOD, AND AGREED TO BE BOUND BY THESE MARKETPLACE TERMS,
          WHICH CONTAIN A MANDATORY INDIVIDUAL ARBITRATION AGREEMENT AND A CLASS
          ACTION AND JURY TRIAL WAIVER. THESE PROVISIONS REQUIRE THE USE OF
          ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
          JURY TRIALS, COURT TRIALS, OR CLASS ACTIONS, AND ALSO LIMIT THE
          REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
        </p>

        <Section title="Welcome to MedX Services Marketplace ('MedX')">
          <p>
            MedX's online marketplace (including www.medxsvc.com, the MedXSVC
            iOS app, and the MedXSVC Android app, collectively the "
            <strong>Marketplace</strong>") is owned and operated by Accelerated
            Healthcare Solutions, LLC ("<strong>AHS</strong>"). and its
            affiliates ("<strong>MedX</strong>," "<strong>MedXSVC</strong>," "
            <strong>we</strong>," "<strong>us</strong>," or "
            <strong>our</strong>"). Our Marketplace enables qualified healthcare
            professionals, group practices, and facilities seeking to provide
            professional healthcare, technical healthcare, and other services ("
            <strong>Healthcare Services</strong>") to engage with other
            healthcare providers in need of Healthcare Services for their
            patients. By accessing or using our Marketplace or any content,
            functionality, or services offered on or through our Marketplace,
            you agree to be legally bound by these Marketplace Terms of Use ("
            <strong>Marketplace Terms</strong>" or "<strong>TOU</strong>"), as
            may be amended from time to time, along with any documents,
            policies, and guidelines incorporated by reference. Some important
            definitions:
          </p>

          <ul className="mt-2 list-disc pl-6">
            <li>
              "<strong>User(s)</strong>," "<strong>you</strong>," or "
              <strong>your</strong>" means any individual who accesses or uses
              the Marketplace, whether registered or not, including individuals
              acting on behalf of legal entities. If you are accessing or using
              the Marketplace on behalf of a legal entity, you represent and
              warrant that you have the authority to agree to these Marketplace
              Terms on behalf of that entity
            </li>
            <li>
              <strong>Healthcare Professional(s)</strong> means any independent
              healthcare worker registered with or otherwise utilizing the
              Marketplace. Examples of Healthcare Professionals include doctors
              of medicine, doctors of osteopathic medicine, advanced practice
              nurses, nurse practitioners, registered nurses, licensed practical
              nurses, medical technicians, and certified nursing assistants.
            </li>
            <li>
              <strong>Healthcare Facility(ies)</strong> means any independent
              healthcare system or facility or medical group practice (or any
              vendor management system, managed service provider, or other party
              acting on behalf of a healthcare system or facility) registered
              with or otherwise utilizing the Marketplace.
            </li>
            <li>
              <strong>Rendering Provider(s)</strong> means any Healthcare
              Professional or Healthcare Facility that is licensed within their
              jurisdiction to provide Healthcare Services to patients and choses
              to make such services available for purchase by Ordering Providers
              for their patients on the Marketplace.
            </li>
            <li>
              <strong>Ordering Provider(s)</strong> means any Healthcare
              Professional that is licensed within their jurisdiction to order
              Healthcare Services for their patients and choses to order such
              services from Rendering Providers on the Marketplace.
            </li>
          </ul>
        </Section>
        <Section title="1. Generally; Updates">
          We reserve the right to modify, suspend, or discontinue any part of
          the Marketplace at any time, with or without notice, at our sole
          discretion. You agree that we will not be liable to you or any third
          party for any modification, suspension, or discontinuance of the
          Marketplace. We may also modify these Marketplace Terms at any time.
          Any changes will be effective immediately upon posting to the
          Marketplace, with an updated effective date. If you do not agree to
          the current version of these Marketplace Terms, you must stop using
          the Marketplace immediately. Your continued use of the Marketplace
          following the posting of the revised Marketplace Terms means that you
          accept and agree to the changes. You are expected to check this page
          each time you access our Marketplace so you are aware of any changes,
          as they are binding on you.
        </Section>
        <Section title="2. Relationships of the Parties">
          <p className="mb-4">
            All users of the Marketplace are bound by these Marketplace Terms,
            regardless of whether you have registered an account with us or are
            accessing the Marketplace as a visitor.
          </p>
          <p className="mb-4">
            Nothing in these Marketplace Terms will be construed to create a
            partnership, joint venture, employer-employee, or agency
            relationship between MedX OR AHS and any user of the Marketplace,
            including any Healthcare Professional or any Healthcare Facility.
          </p>
          <p className="mb-4">
            A Rendering Provider may work for themselves as an independent
            contractor or be employed by a Healthcare Facility or another
            Rendering Provider registered with the Marketplace. Regardless of
            the relationship, all Healthcare Professionals and Healthcare
            Facilities are independent contractors of MedX and are not
            employees, agents, or representatives of MedX. Rendering Providers
            acknowledge and agree that posting and rendering services on the
            Marketplace will not, under any circumstances, create an employment
            relationship between MedX and the Rendering Provider. Ordering
            Providers acknowledge and agree that purchasing services on the
            Marketplace will not, under any circumstances, create an employment
            relationship between MedX and the Ordering Provider. Each Healthcare
            Professional is solely responsible for setting their work schedules,
            providing the tools and instruments necessary to perform their
            services, and ensuring compliance with all applicable laws and
            regulations governing their professional practice, including abiding
            by the standards of licensing boards, certifying authorities, or
            professional specialty boards governing their practice. Furthermore,
            each Rendering Provider is solely responsible for the maintenance of
            their facilities, equipment, and any medical devices used, to ensure
            that such devices receive the appropriate maintenance and are of the
            expected level of quality to provide care under the policies,
            procedures, and regulations of their practice jurisdiction. MedX
            does not and has no right to (i) supervise, direct, or control any
            Healthcare Professional, including in their performance of any
            Healthcare Services, (ii) employ, hire, discipline, or fire any
            Healthcare Professional, (iii) set the conditions of employment of
            any Healthcare Professional, including work schedules, benefits, or
            compensation as applicable, or (iv) direct any Healthcare
            Professional to accept or reject any order or engagement.
          </p>
          Similarly, Rendering Providers are independent third-party entities
          and are not partners, joint venturers, agents, affiliates, or
          subsidiaries of MedX. Ordering Providers, Rendering Providers, and
          Healthcare Facilities acknowledge and agree that MedX: (a) is merely a
          technology services provider making this Marketplace available for
          Healthcare Professionals to interact, (b) is not a party to the actual
          transaction between Ordering Providers and Rendering Providers, (c) is
          not the seller or provider of any Healthcare Services, (d) does not
          provide, assign, or employ Healthcare Professionals, and (e) is not a
          staffing agency or healthcare service provider. MedX holds no
          liability for the actions or omissions of any Healthcare Professional
          or Healthcare Facility in connection with the Marketplace or any
          Healthcare If you choose, or are provided with, a username, Services.
        </Section>

        <Section title="3. User Age and Access">
          This Marketplace is offered and available to users 18 years of age or
          older who reside in the United States or any of its territories or
          possessions. By using this Marketplace, you represent and warrant that
          you are of legal age to form a binding contract with MedX and meet all
          of the foregoing eligibility requirements. If you do not meet these
          requirements, you must not access or use the Marketplace.
        </Section>

        <Section title="4. Geographic Restrictions">
          Our Marketplace is controlled, operated, and administered from our
          offices in the United States of America.  We make no representation
          that the Marketplace is appropriate or available for use at locations
          outside the United States, and access to it is prohibited from
          territories where the Marketplace or services available through the
          Marketplace are illegal.  Access to the Marketplace may not be legal
          by certain persons or in certain countries. You may not use the
          Marketplace in violation of United States export laws and regulations.
          If you access the Marketplace from a location outside of the United
          States, you are responsible for compliance with all local laws if and
          to the extent local laws are applicable.
        </Section>

        <Section title="5. Accessing the Marketplace and Account Security">
          <p className="mb-4">
            We reserve the right to change or discontinue this Marketplace and
            any service or content we provide on the Marketplace, at our sole
            discretion and without notice. We will not be liable if, for any
            reason, all or any part of the Marketplace is unavailable at any
            time or for any period. From time to time, we may restrict user
            access, including registered user access, to some parts of the
            Marketplace or the entire Marketplace. We may permanently or
            temporarily terminate or suspend your access to your user account
            with or without notice and without liability, for any reason,
            including if, in our sole determination, you violate any provision
            of these Marketplace Terms, or for no reason. Upon termination for
            any reason or no reason, you continue to be bound by these
            Marketplace Terms.
          </p>

          <p className="mb-4">You are responsible for both:</p>
          <ul className="mb-4 list-disc pl-6">
            <li>
              Making all arrangements necessary for you to have access to the
              Marketplace.
            </li>
            <li>
              Ensuring that all persons who access the Marketplace through your
              internet connection are aware of these Marketplace Terms and
              comply with them.
            </li>
          </ul>

          <p className="mb-4">
            You may be required to register with MedX to access certain services
            or areas of the Marketplace. It is a condition of your use of the
            Marketplace that all the information you provide on the Marketplace
            is correct, current, and complete. You agree that all information
            you provide to register with the Marketplace or otherwise,
            including, but not limited to, through the use of any interactive
            features on the Marketplace, is governed by our{" "}
            <a
              href="https://www.medxsvc.com/legal/privacy"
              className="text-blue-600 hover:underline"
            >
              Privacy Policy
            </a>
            , and you consent to all actions we take with respect to your
            information consistent with our Privacy Policy.
          </p>

          <p className="mb-4">
            If you choose or are provided with a username, password, or any
            other information as part of our security procedures, you must treat
            such information as confidential. If you use the Marketplace, you
            are responsible for maintaining the confidentiality of your account
            and password and for restricting access to your computer, and you
            agree to accept responsibility for all activities that occur under
            your account or password. In addition to all other rights available
            to MedX, we reserve the right to disable any username, password, or
            other identifier, whether chosen by you or provided by us, at any
            time in our sole discretion for any or no reason, including if, in
            our opinion, you have violated any provision of these Marketplace
            Terms. Upon any such termination, your right to use the Marketplace
            will immediately cease.
          </p>

          <p className="mb-4">
            You agree to notify us immediately of any unauthorized access to or
            use of your username or password or any other breach of security.
            You also agree to ensure that you exit your account at the end of
            each session. You should use particular caution when accessing your
            account from a public or shared computer so that others are not able
            to view or record your password or other personal information.
          </p>
        </Section>

        <Section title="6. Healthcare Professional Credentialing and Compliance Requirements">
          <p className="mb-4">
            For any Healthcare Professional to use the Marketplace and engage
            with other Healthcare Professionals, the Healthcare Professional
            must complete the following:
          </p>

          <ul className="mb-4 list-disc space-y-4 pl-6">
            <li>
              <strong className="font-bold">
                Identity Verification Process
              </strong>
              . The Healthcare Professional must participate in a comprehensive
              identity verification process, which includes submitting current
              images of the Healthcare Professional and images of government
              issued identifier(s). Such documentation shall not be falsified or
              altered, and must be effective and not revoked by the issuing
              government agency. Failure to do so will prevent full activation
              of your account.
            </li>

            <li>
              <strong className="font-bold">Credentialing Process</strong>. The
              Healthcare Professional and Healthcare Facility must participate
              in a comprehensive credentialing process, which includes
              submitting accurate information and satisfying all credentialing
              requirements. Failure to do so will prevent full activation of
              your account.
            </li>

            <li>
              <strong className="font-bold">Accurate Information</strong>. The
              Healthcare Professional must provide accurate information.
              Providing false or misleading information during the credentialing
              process or thereafter will lead to account suspension or
              termination.
            </li>
          </ul>
        </Section>

        <Section title="7. Notification of Status Changes">
          <p className="mb-4">
            Any Healthcare Professional must maintain all necessary licenses,
            permits, registrations, and certifications required to order or
            provide Healthcare Services in their jurisdiction(s). You must
            immediately notify us in writing if any of the following occurs:
          </p>

          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              Any change in eligibility to participate in Government Health Care
              Programs or third-party payer programs, including the Medicare or
              Medicaid programs or any other federal health care program as such
              term is defined at 42 U.S.C. Title 42 § 1320a-7b;
            </li>

            <li>
              Any disciplinary action, suspension, revocation, or limitation of
              your medical license or professional status;
            </li>

            <li>
              Denial, suspension, or revocation of membership, reappointment, or
              clinical privileges at any medical or health care facility for
              disciplinary reasons;
            </li>

            <li>Provision of healthcare services while impaired; or</li>

            <li>
              Any violation of these Marketplace Terms or applicable laws.
            </li>
          </ul>

          <p className="font-semibold">
            Compliance with this Section 7 is mandatory for maintaining your
            account and the ability to provide Healthcare Services via the
            Marketplace.
          </p>
        </Section>

        <Section title="8. Intellectual Property Rights">
          <p className="mb-4">
            For the purposes of these Marketplace Terms, "Intellectual Property
            Rights" means all patent rights, copyright rights, moral rights,
            rights of publicity, trademark, trade dress, and service mark
            rights, goodwill, trade secret rights, and any and all other
            intellectual property rights as may now exist or hereafter come into
            existence, and all applications therefore and registrations,
            renewals, and extensions thereof, under the laws of any state,
            country, territory, or other jurisdiction.
          </p>

          <p className="mb-4">
            The Marketplace and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, graphics, illustrations, images, logos, patents,
            trademarks, service marks, copyrights, photographs, video, and
            audio, and the design, selection, and arrangement thereof) and User
            Content belonging to other users (the "MedX Content"), and all
            Intellectual Property Rights related thereto, are the exclusive
            property of MedX and its licensors, providers, distributors, and
            suppliers and are protected by the United States and international
            copyright, trademark, patent, trade secret, and other intellectual
            property or proprietary rights laws.
          </p>

          <p className="mb-4">
            Except as explicitly provided herein, nothing in these Marketplace
            Terms will be deemed to create a license in or under any such
            Intellectual Property Rights, and you will not sell, license, rent,
            modify, distribute, copy, reproduce, transmit, publicly display,
            publicly perform, publish, download, store, adapt, edit, or create
            derivative works of any MedX Content, except as follows:
          </p>

          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              Your computer may temporarily store copies of such MedX Content in
              RAM incidental to your accessing and viewing the MedX Content.
            </li>
            <li>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of the Marketplace for your own personal, non-commercial use and
              not for further reproduction, publication, or distribution.
            </li>
            <li>
              If we provide desktop, mobile, or other applications for download,
              you may download a single copy to your computer or mobile device
              solely for your own personal, non-commercial use, provided you
              agree to be bound by our end user license agreement for such
              applications.
            </li>
          </ul>

          <p className="mb-4">You must not:</p>

          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>Modify copies of any MedX Content from this Marketplace.</li>
            <li>
              Use any illustrations, photographs, video or audio sequences, or
              any graphics separately from the accompanying text.
            </li>
            <li>
              Delete or alter any copyright, trademark, or other proprietary
              rights notices from copies of MedX Content from this Marketplace.
            </li>
          </ul>

          <p className="mb-4">
            Notwithstanding the restrictions on commercial use set forth herein,
            MedX expressly permits the use of the Marketplace for the purpose of
            providing or obtaining Healthcare Services, including engaging,
            communicating, and transacting with other users, subject to the
            Prohibited Uses outlined in Section 13 below.
          </p>

          <p className="mb-4">
            If you print, copy, modify, download, or otherwise use or provide
            any other person with access to any part of the Marketplace in
            breach of the Marketplace Terms, your right to use the Marketplace
            will stop immediately and you must, at our option, return or destroy
            any copies of the MedX Content you have made. No right, title, or
            interest in or to the Marketplace or any content on the Marketplace
            is transferred to you, and all rights not expressly granted are
            reserved by MedX. Any use of the Marketplace not expressly permitted
            by these Marketplace Terms is a breach of these Marketplace Terms
            and may violate copyright, trademark, and other laws.
          </p>
        </Section>
        <Section title="9. Trademarks">
          “MedX SERVICES,” “MedX”, “MedXSVC”, “Accelerated Healthcare Solution,
          LLC.”, “AHS,” and all related names, logos, product and service names,
          designs, and slogans are trademarks of Accelerated Healthcare
          Solutions, LLC. or its affiliates or licensors. You must not use such
          marks without the prior written permission of AHS. All other names,
          logos, product and service names, designs, and slogans on this
          Marketplace are the trademarks of their respective owners.
        </Section>

        <Section title="10. User Content">
          <p className="mb-4">
            For purposes of these Marketplace Terms, "User Content" refers to
            all forms of content, including but not limited to text, reviews,
            questions, feedback, comments, images, videos, information, Personal
            Data (as defined below), and other forms of media or communication
            that users post, upload, publish, submit, transmit, or include in
            their profile or on any public area of the Marketplace.
          </p>

          <p className="mb-4">
            We claim no ownership rights over your User Content, and, as between
            you and us, it remains yours. MedX has the right (but not the
            obligation), in its sole discretion, to remove any User Content on
            the Marketplace.
          </p>

          <p className="mb-4">
            By submitting, posting, displaying, providing, or otherwise making
            available any User Content on or through the Marketplace, and
            subject to any applicable data privacy laws, you hereby expressly
            grant, and you represent and warrant that you have all rights
            necessary to grant, to MedX a royalty-free, transferable, perpetual,
            irrevocable, non-exclusive, and worldwide license, with the right to
            grant and authorize sublicenses, to use, reproduce, modify, publish,
            list information regarding, edit, translate, distribute, syndicate,
            publicly perform, publicly display, and make derivative works of all
            such User Content and your name, voice, and/or likeness as contained
            in your User Content, in whole or in part, and in any form, media,
            or technology, whether now known or hereafter developed, for use in
            connection with the Marketplace and MedX's (and its successors')
            business, including, without limitation, for promoting and
            redistributing part or all of the Marketplace (and derivative works
            thereof) in any media formats and through any media channels.
          </p>

          <p className="mb-4">
            In connection with your User Content, you affirm, represent, and
            warrant the following:
          </p>

          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              you have the written consent of each and every identifiable
              natural person referred to or mentioned in the User Content, if
              any, to use such person's name or likeness in the manner
              contemplated by the Marketplace and these Marketplace Terms, and
              each such person has released you from any liability that may
              arise in relation to such use;
            </li>

            <li>
              you have obtained and are solely responsible for obtaining all
              consents as may be required by applicable law to post any User
              Content relating to third parties;
            </li>

            <li>
              your User Content and MedX's use thereof as contemplated by these
              Marketplace Terms and the Marketplace will not violate any
              applicable law or infringe any rights of any third party,
              including, but not limited to, any Intellectual Property Rights
              and privacy rights;
            </li>

            <li>
              MedX may exercise the rights to your User Content granted under
              these Marketplace Terms without liability for payment of any guild
              fees, residuals, payments, fees, or royalties payable under any
              collective bargaining agreement or otherwise;
            </li>

            <li>
              your User Content does not include sexually suggestive content;
              hate speech, threats, or direct attacks on an individual or group;
              abusive, harassing, tortious, defamatory, vulgar, obscene,
              libelous, invasive of another's privacy, hateful, or racially,
              ethnically, or otherwise objectionable content; content that
              contains self-harm or excessive violence; illegal content or
              content in furtherance of harmful or illegal activities; malicious
              programs or code; any person's personal information without such
              person's consent; and/or spam, machine-generated content, or
              unsolicited messages; and
            </li>

            <li>
              to the best of your knowledge, all of your User Content and all
              other information that you provide to us is truthful and accurate.
            </li>
          </ul>

          <p className="mb-4">
            MedX takes no responsibility and assumes no liability for any User
            Content that you or any other user or third party submits, posts,
            displays, provides, or otherwise makes available on or through the
            Marketplace. You will be solely responsible for your User Content
            and the consequences of submitting it, posting it, displaying it,
            providing it, or otherwise making it available on or through the
            Marketplace, and you agree that we are acting only as a passive
            conduit for your online distribution of your User Content. You
            understand and agree that you may be exposed to User Content that is
            inaccurate, objectionable, inappropriate for children, or otherwise
            unsuited to your purpose, and you agree that MedX will not be liable
            for any damages you allege to incur as a result of or relating to
            any User Content.
          </p>
        </Section>

        <Section title="11. Usage Data">
          <p className="mb-4">
            MedX may collect, maintain, process, and use, or you may provide to
            MedX, diagnostic, technical, usage, and related data or information,
            including information about your computers, mobile devices, systems,
            and software (collectively, "<strong>Usage Data</strong>"). You
            agree that all Usage Data is owned solely and exclusively by MedX,
            and, to the extent any ownership rights in or to the Usage Data vest
            in you, you hereby assign to MedX all rights, title, and interest in
            and to the same. Accordingly, MedX may use the Usage Data or any
            portion thereof for any lawful purpose, including, without
            limitation: (a) to provide and maintain the Marketplace, (b) to
            develop and improve the Marketplace, (c) to monitor your usage of
            the Marketplace, (d) for research and analytics and for MedX's other
            business purposes, and (e) to share analytics and other derived
            Usage Data with third parties, solely in de-identified or aggregated
            form. The Marketplace may contain technological measures designed to
            prevent unauthorized or illegal use of the Marketplace. You
            acknowledge and agree that MedX may use these and other lawful
            measures to verify your compliance with these Marketplace Terms and
            to enforce MedX's rights, including all Intellectual Property
            Rights, in and to the Marketplace.
          </p>
        </Section>
        <Section title="12. Feedback">
          <p className="mb-4">
            To the extent you provide any suggestions, recommendations, or other
            feedback relating to the Marketplace or any other MedX products or
            services, (collectively, "<strong>Feedback</strong>"), such Feedback
            is non-confidential, and you hereby grant, and you represent and
            warrant that you have all rights necessary to grant, to MedX a
            non-exclusive, perpetual, irrevocable, transferable, royalty-free,
            and worldwide license, with the right to grant and authorize
            sublicenses, to implement, use, modify, and otherwise exploit, in
            any way without restriction, the Feedback, without any fees,
            attribution, or other obligations to you or any third party.
          </p>
        </Section>
        <Section title="13. Prohibited Uses and Practices">
          <p className="mb-4">
            You may use the Marketplace only for lawful purposes and in
            accordance with these Marketplace Terms. You agree not to use the
            Marketplace for any of the following:
          </p>
          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              Utilizing the Marketplace for the sale or exchange of goods,
              products, or services other than Healthcare Services.
            </li>
            <li>
              Engaging in any form of commercial activity that involves the use
              of the Marketplace's content, MedX's trademarks, or any other
              intellectual property for purposes other than providing or
              obtaining Healthcare Services as permitted herein, without the
              express written consent of MedX.
            </li>
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise.
            </li>
            <li>
              To send, knowingly receive, upload, download, use, or re-use any
              material that does not comply with these Marketplace Terms.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material without our prior written consent, including
              any "junk mail," "chain letter," "spam," or any other similar
              solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate MedX, a MedX employee,
              another user, or any other person or entity (including, without
              limitation, by using email addresses or screen names associated
              with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Marketplace, or which, as determined by
              us, may harm MedX or users of the Marketplace, or expose them to
              liability.
            </li>
            <li>
              To solicit or otherwise contact any Healthcare Providers or
              Healthcare Facilities listed on the MedX marketplace for any use
              outside of contacting a Rendering Provider with questions about a
              service they have listed or contacting a Rendering Provider with
              questions about an order that you have placed with them, unless
              you have a previously established independent business arrangement
              with such Healthcare Provider or Healthcare Facility.
            </li>
          </ul>
          <p className="mb-4">Additionally, you agree not to:</p>
          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              Use the Marketplace in any manner that could disable, overburden,
              damage, or impair the Marketplace or interfere with any other
              party's use of the Marketplace, including their ability to engage
              in real-time activities through the Marketplace.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or
              means to access the Marketplace for any purpose, including
              monitoring or copying any of the MedX Content on the Marketplace.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on
              the Marketplace, or for any other purpose not expressly authorized
              in these Marketplace Terms, without our prior written consent.
            </li>
            <li>
              Use any device, software, or routine that interferes with the
              proper working of the Marketplace.
            </li>
            <li>
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of the Marketplace, the server on which the
              Marketplace is stored, or any server, computer, or database
              connected to the Marketplace.
            </li>
            <li>
              Attack the Marketplace via a denial-of-service attack or a
              distributed denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working of the
              Marketplace.
            </li>
          </ul>
        </Section>

        <Section title="14. Privacy and Data Protection">
          <p className="mb-4">
            MedX may process information about Healthcare Professionals and
            Healthcare Facilities for legal, administrative, and operational
            purposes ("Personal Data"), which includes but is not limited to:
          </p>
          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              Credentialing information and any required documentation to verify
              credentials, including name, address, social security number,
              birth date, and photos uploaded for identification purposes;
            </li>
            <li>
              Information for integration with Healthcare Professionals' or
              Healthcare Facilities' technology systems, payment processing, and
              other administrative functions; and
            </li>
            <li>
              Personal data to comply with legal requirements and obligations to
              third parties.
            </li>
          </ul>
          <p className="mb-4">
            MedX may also share this Personal Data of Healthcare Professionals
            or Healthcare Facilities with other Healthcare Professionals or
            Healthcare Facilities listed in the Marketplace, business partners,
            service providers (such as credential verification services,
            orientation/training providers, advisers, payroll administrators),
            regulatory authorities, and/or governmental or quasi-governmental
            organizations. These organizations may use your Personal Data to
            provide the applicable services, and in some limited instances may
            use non-sensitive Personal Data (such as your name, phone number,
            and email address) for their own business or government function
            purposes.
          </p>
          <p className="mb-4">
            Note some portions of the Marketplace may also be public in nature,
            such as published service listings, allowing other users of the
            Marketplace to see information such as your name and any other
            information you choose to share through those channels, including
            any documents or photos uploaded and shared with participants.
            Please do not provide Personal Data you would not want to be public.
          </p>
          <p className="mb-4">
            By using the Marketplace, you acknowledge and consent to the
            collection, use, and disclosure of your Personal Data as outlined in
            our Privacy Policy and for the purposes described above, including
            the sharing of information with Healthcare Professionals, Healthcare
            Facilities, and service providers, and for the integration with the
            technological platforms of our payment vendors and Healthcare
            Professionals and Healthcare Facilities.
          </p>
        </Section>

        <Section title="15. Electronic Notification">
          <p className="mb-4">
            By agreeing to these Marketplace Terms, you agree and consent to
            receive all forms of communications related to this Marketplace
            Terms, including agreements, notices, disclosures, and other
            important information ("Notices"), through electronic means. This
            includes, but is not limited to, receiving Notices via email, text
            messages to your mobile device, notifications within our mobile
            applications, or by posting Notices directly on the Marketplace.
          </p>
          <p className="mb-4">
            Your ability to unsubscribe from electronically receiving these
            Notices depends on the nature of the Notice itself, as detailed
            below:
          </p>
          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              Legal and Transactional Notices: These include, but are not
              limited to, updates to our Marketplace Terms, Privacy Policy, and
              other communications that contain important information related to
              your legal rights, responsibilities, and obligations. You may not
              unsubscribe from receiving these Notices as they are essential for
              your continued use of our Marketplace.
            </li>
            <li>
              Other Communications: Occasionally, we may send you Notices
              regarding promotions, offers, new services, or other marketing
              messages. You have the option to unsubscribe from receiving these
              types of communications by following the unsubscribe instructions
              provided in the Notice.
            </li>
          </ul>
          <p className="mb-4">
            You agree that all Notices provided electronically satisfy any legal
            requirement that such communications be in writing. Notices are
            considered duly issued and received under these Marketplace Terms
            when:
          </p>
          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              They are personally delivered, sent through certified or
              registered mail (with return receipt requested), or the receipt is
              physically confirmed.
            </li>
            <li>
              Receipt is electronically confirmed, in cases where Notices are
              sent via fax, text, push notification, or email.
            </li>
            <li>
              Posted to the applicable page or location on the Marketplace.
            </li>
            <li>
              The date indicated as 'delivered' on the tracking system of any
              recognized courier or delivery service with delivery tracking
              capabilities, regardless of the delivery speed chosen.
            </li>
          </ul>
          <p className="mb-4">
            Please ensure your contact information, including email address and
            mobile phone number, is current in our records to guarantee the
            delivery of these Notices.
          </p>
        </Section>

        <Section title="16. Electronic Signatures">
          <p className="mb-4">
            By using the Marketplace, you agree that (i) your electronic
            signature is the legal equivalent of your signature as if actually
            signed in writing; and (ii) no certification authority or other
            third-party verification is necessary to validate your electronic
            signature, and the absence of such certification or third-party
            verification will not undermine the legal effectiveness of your
            electronic signature.
          </p>
        </Section>

        <Section title="17. Reliance on Information Posted">
          <p className="mb-4">
            The information presented on or through the Marketplace is made
            available solely for general information purposes. We do not warrant
            the accuracy, completeness, or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk.
            We disclaim all liability and responsibility arising from any
            reliance placed on such MedX Content by you or any other visitor to
            the Marketplace, or by anyone who may be informed of any of its
            contents.
          </p>
          <p className="mb-4">
            This Marketplace may include content provided by third parties,
            including MedX Content provided by other users, bloggers, and
            third-party licensors, syndicators, aggregators, and/or reporting
            services. All statements and/or opinions expressed in these
            materials, and all articles and responses to questions and other
            content, other than the content provided by MedX, are solely the
            opinions and the responsibility of the person or entity providing
            those materials. These materials do not necessarily reflect the
            opinion of MedX. We are not responsible, or liable to you or any
            third party, for the content or accuracy of any materials provided
            by any third parties.
          </p>
        </Section>

        <Section title="18. Changes to the Marketplace">
          <p className="mb-4">
            We may update the content on this Marketplace from time to time, but
            its content is not necessarily complete or up to date. Any of the
            material on the Marketplace may be out of date at any given time,
            and we are under no obligation to update such material.
          </p>
        </Section>

        <Section title="19. Linking to the Marketplace and Social Media Features">
          <p className="mb-4">
            You may link to our homepage, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it, but you must not establish a link in such a way as to suggest
            any form of association, approval, or endorsement on our part
            without our express written consent.
          </p>
          <p className="mb-4">
            This Marketplace may provide certain social media features that
            enable you to:
          </p>
          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>
              Link from your own or certain third-party websites to certain
              content on the Marketplace.
            </li>
            <li>
              Send emails or other communications with certain content or links
              to certain content on the Marketplace.
            </li>
            <li>
              Cause limited portions of content on the Marketplace to be
              displayed or appear to be displayed on your own or certain
              third-party websites.
            </li>
          </ul>
          <p className="mb-4">
            You may use these features solely as they are provided by us, and
            solely with respect to the content they are displayed with, and
            otherwise in accordance with any additional terms and conditions we
            provide with respect to such features. Subject to the foregoing, you
            must not:
          </p>
          <ul className="mb-4 list-disc space-y-2 pl-6">
            <li>Establish a link from any website that you do not own.</li>
            <li>
              Cause the Marketplace or portions of it to be displayed on, or
              appear to be displayed by, any other website, for example,
              framing, deep linking, or in-line linking.
            </li>
            <li>
              Link to any part of the Marketplace other than the homepage.
            </li>
            <li>
              Otherwise, take any action with respect to the materials on this
              Marketplace that is inconsistent with any other provision of these
              Marketplace Terms.
            </li>
          </ul>
          <p className="mb-4">
            The website from which you are linking or on which you make certain
            content accessible must comply in all respects with these
            Marketplace Terms.
          </p>
          <p className="mb-4">
            You agree to cooperate with us in causing any unauthorized framing
            or linking immediately to stop. We reserve the right to withdraw
            linking permission without notice.
          </p>
          <p className="mb-4">
            We may disable all or any social media features and any links at any
            time without notice at our discretion.
          </p>
        </Section>

        <Section title="20. Links from the Marketplace">
          <p className="mb-4">
            If the Marketplace contains links to other sites and resources
            provided by third parties, these links are provided for your
            convenience only. We have no control over the contents of those
            sites or resources and accept no responsibility for them or for any
            loss or damage that may arise from your use of them. If you decide
            to access any of the third-party websites linked to the Marketplace,
            you do so entirely at your own risk and subject to the terms and
            conditions of use for such websites.
          </p>
        </Section>

        <Section title="21. DISCLAIMER OF WARRANTIES">
          <p className="mb-4">
            You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the internet or the Marketplace
            will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints
            to satisfy your requirements for anti-virus protection and accuracy
            of data input and output, and for maintaining a means external to
            our Marketplace for any reconstruction of any lost data. TO THE
            FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS
            OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES,
            OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
            COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
            MATERIAL DUE TO YOUR USE OF THE MARKETPLACE OR ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE MARKETPLACE OR YOUR DOWNLOADING OF ANY MATERIAL
            POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
          </p>
          <p className="mb-4">
            YOUR USE OF THE MARKETPLACE, ITS CONTENT, AND ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE MARKETPLACE IS AT YOUR OWN RISK. THE
            MARKETPLACE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH
            THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
            WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
            NEITHER MedX NOR ANY PERSON ASSOCIATED WITH MedX MAKES ANY WARRANTY
            OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE MARKETPLACE.
            WITHOUT LIMITING THE FOREGOING, NEITHER MedX NOR ANYONE ASSOCIATED
            WITH MedX REPRESENTS OR WARRANTS THAT THE MARKETPLACE, ITS CONTENT,
            OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE MARKETPLACE WILL BE
            ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL
            BE CORRECTED, THAT OUR MARKETPLACE OR THE SERVER THAT MAKES IT
            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT
            THE MARKETPLACE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            MARKETPLACE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p className="mb-4">
            TO THE FULLEST EXTENT PROVIDED BY LAW, MedX HEREBY DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
            OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
            PURPOSE.
          </p>
          <p className="mb-4">
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </Section>

        <Section title="22. LIMITATION ON LIABILITY">
          <p className="mb-4">
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL MedX, ITS
            AFFILIATES, ITS LICENSORS, ITS SERVICE PROVIDERS, OR ITS OR THEIR
            EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF
            ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
            WITH YOUR USE, OR INABILITY TO USE, THE MARKETPLACE, ANY WEBSITES
            LINKED TO IT, ANY CONTENT ON THE MARKETPLACE OR SUCH OTHER WEBSITES,
            INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
            OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY,
            PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
            PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS
            OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
          </p>
          <p className="mb-4">
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </Section>
        <Section title="23. Indemnification">
          You will defend, indemnify, and hold harmless MedX, our affiliates,
          and each of our and their respective officers, directors, employees,
          contractors, suppliers, representatives, and agents from any and all
          claims, demands, liabilities, damages, losses, costs, and expenses,
          including, but not limited to, reasonable attorneys’ fees, that arise
          from or relate to (i) your access or use of the Marketplace, (ii)
          violation of any applicable law that protects MedX or our legal rights
          or those of any third party that your actions have damaged, (iii) your
          violation of these Marketplace Terms or any applicable law, including,
          but not limited to, infringement by you of any intellectual property
          rights owned by MedX or any third party, or (iv) any and all
          activities that occur under or in connection with your account,
          username, and/or password.  We reserve the right to assume the
          exclusive defense and control of any matter otherwise subject to
          indemnification by you, in which event you will assist and cooperate
          with MedX in asserting any available defenses.  YOU AGREE TO BE
          RESPONSIBLE FOR ALL SUCH DAMAGES, CLAIMS, OR LOSSES TO THE FULLEST
          EXTENT PERMITTED BY LAW, EVEN IF THEY ARE CAUSED BY OUR ACTIVE OR
          PASSIVE NEGLIGENCE, EVEN IF WE ARE ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES, CLAIMS, OR LOSSES, AND EVEN IF THEY ARE REASONABLY
          FORESEEABLE.
        </Section>

        <Section title="24. Informal Dispute Resolution">
          <p className="mb-4">
            You and MedX agree that good-faith informal efforts to resolve
            disputes between you and MedX often can result in a prompt,
            low-cost, and mutually beneficial outcome. You and MedX therefore
            agree that, before either party demands arbitration against the
            other in connection with any dispute, controversy, or claim related
            to, arising from, or regarding your use of the Marketplace, your
            relationship with MedX, your relationship with a Healthcare
            Professional, your relationship with a Healthcare Facility, or these
            Marketplace Terms (including previous versions) (“Dispute”), you and
            MedX will personally meet and confer, via telephone or
            videoconference, in a good-faith effort to resolve the Dispute
            informally.
          </p>
          <p className="mb-4">
            ‍ Multiple individuals initiating claims cannot participate in the
            same informal telephonic dispute resolution conference. If you are
            represented by counsel, your counsel may participate in the
            conference, but you will also fully participate in the conference.
            The party initiating the claim must give notice to the other party
            in writing of their intent to initiate an informal dispute
            resolution conference, which will occur within 60 days after the
            other party receives such notice, unless an extension is mutually
            agreed upon by the parties.
          </p>
          <p className="mb-4">
            Your address for such notices is the one associated with your
            account, with an email copy to the email address that you have
            provided to MedX. MedX’s address for such notice is:
          </p>
          <p className="mb-4">
            ‍ Accelerated Healthcare Solutions, LLC. Attention: MedX Legal P.O.
            Box 55294 Little Rock, AR 72215
          </p>
          <p className="mb-4">
            In the interval between a party receiving such notice and the
            informal dispute resolution conference, the parties will be free to
            attempt to resolve the initiating party’s claims. Engaging in an
            informal dispute resolution conference is a condition precedent that
            must be fulfilled before commencing arbitration. The statute of
            limitations and any filing fee deadlines will be tolled while the
            parties engage in the informal dispute resolution process required
            by this Section.
          </p>
          <p className="mb-4">
            Notwithstanding the provisions of this Section, you are solely
            responsible for your interactions with other Marketplace users. We
            reserve the right, but have no obligation, to monitor disputes
            between you and other Marketplace users. MedX will have no liability
            for your interactions with other users, or for any user’s action or
            inaction.
          </p>
          ‍
          <p className="mb-4">
            To initiate arbitration following the conclusion of the informal
            dispute resolution process required by this Section, a party must
            provide the other party with a written demand for arbitration as
            provided in Section 26.
          </p>
        </Section>

        <Section title="25. No Third-Party Beneficiaries">
          Except for the Arbitration Agreement below, we and you are the only
          parties entitled to enforce these Marketplace Terms.  These
          Marketplace Terms do not and are not intended to confer any rights or
          remedies upon any person other than you and MedX.  You also agree that
          if we assign, transfer, or subcontract any of our rights or
          obligations under these Marketplace Terms to any third party in
          writing, such third party may enforce the Marketplace Terms that are
          assigned, transferred, or sub-contracted.
        </Section>

        <Section title="26. MUTUAL AGREEMENT TO ARBITRATE / CLASS ACTION WAIVER">
          <p className="mb-4">
            READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO
            ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
            RELIEF FROM US. To the fullest extent permitted by applicable law,
            you and MedX (each a “<strong>party</strong>” and collectively the
            <strong>“parties</strong>”) agree to arbitrate any and all disputes,
            controversies, or claims between the parties including those between
            a Healthcare Professional and MedX, a Healthcare Facility and MedX,
            a Healthcare Professional and a Healthcare Facility, that arise out
            of or relate to, directly or indirectly: (a) these Marketplace
            Terms, including the formation, existence, breach, termination,
            enforcement, interpretation, validity, or enforceability thereof,
            and including any previous versions, (b) access to or use of our
            Marketplace or any other service, including receipt of any
            advertising, marketing, or other communications from us, (c) any
            transactions through, by, or using our Marketplace or any other
            service, (d) the Healthcare Services, or (e) any other aspect of
            your relationship or transactions with us, directly or indirectly,
            as a user or consumer (“<strong>Claim</strong>” or collectively,{" "}
            <strong>“Claims</strong>”). For the avoidance of doubt, “Claims”
            includes claims by or against MedX, Healthcare Professional claims
            against any Healthcare Facility, and claims by or against any
            current or future parents, subsidiaries, affiliates, predecessors or
            successors of MedX, a Healthcare Facility, and these entities’
            respective partners, officers, directors, supervisors, managers,
            employees, insurers, agents, investors, contractors, and vendors.
            The parties agree that any Healthcare Facility that a Healthcare
            Professional performs work at or for is intended to be a third-party
            beneficiary of this Arbitration Agreement. ‍
          </p>
          <p className="mb-4">
            To the fullest extent permitted by applicable law, and subject to
            the exclusions described below, the Claims include, but are not
            limited to, claims of discrimination, harassment, retaliation,
            wrongful termination and unfair competition, wage and hour claims,
            equity claims, tort claims, contract claims, common law claims, and
            claims based upon any federal, state, or local ordinance, statute,
            regulation, or constitutional provision. This Arbitration Agreement
            will apply, without limitation, to all Claims that arose or were
            asserted before or after your consent to these Marketplace Terms.
            However, if there are any Claims pending in court between you and
            MedX at the time of your acknowledgement and consent to these
            Marketplace Terms, then this Arbitration Agreement will not apply to
            those Claims.
          </p>
          <p className="mb-4">
            The parties agree that nothing in this Arbitration Agreement is
            intended to prevent either party from seeking and obtaining
            temporary or preliminary injunctive relief in court to prevent
            irreparable harm to their confidential information or trade secrets
            pending the conclusion of any arbitration.
          </p>
          <p className="mb-4">
            This Arbitration Agreement does not apply to claims for workers’
            compensation benefits, unemployment insurance benefits, state or
            federal disability insurance, claims that are subject to the
            exclusive jurisdiction of the National Labor Relations Board, claims
            related to sexual harassment and sexual assault arising under
            federal, state, or tribal law unless you elect to arbitrate these
            claims, or any other claims that have been expressly excluded from
            mandatory arbitration by the Federal Arbitration Act or a governing
            law not preempted by the Federal Arbitration Act. If either party
            brings both arbitrable and non-arbitrable claims in the same action
            or related actions, both agree that the non-arbitrable claims will
            be stayed until the conclusion of the arbitration, to the fullest
            extent permitted by law. This Arbitration Agreement does not
            restrict or preclude you from communicating with, filing an
            administrative charge or claim with, or providing testimony to any
            governmental entity about any actual or potential violation of law
            or obtaining relief through a government agency process, where
            required by law.
          </p>

          <p className="mb-4">
            The parties agree that Claims will be resolved on an individual
            basis only, and not on a class, collective, or representative basis
            on behalf of other individuals or Healthcare Professionals,
            including under California’s Private Attorneys General Act (“PAGA”),
            to the fullest extent permitted by applicable law (“Class Waiver”).
            Any claim that all or part of the Class Waiver is invalid,
            unenforceable, unconscionable, void or voidable may be determined
            only by a court. In no case may class, collective, or representative
            claims proceed in arbitration on behalf of other individuals or
            Healthcare Professionals. ‍ The parties agree that to initiate an
            arbitration a party must provide the other party with a written
            demand for arbitration and file the demand with New Era ADR. The
            arbitration will be conducted by a single, neutral arbitrator and
            will be administered pursuant to the applicable New Era ADR Rules
            then in effect (available at: www.neweraadr.com) unless such Rules
            are in conflict with this Agreement, in which case, the Arbitration
            Agreement in these Marketplace Terms will control.  If your Claim(s)
            does not exceed $10,000, then the arbitration will be conducted
            solely based on documents and/or evidence the parties submit to the
            arbitrator, unless either party requests a hearing, or the
            arbitrator determines that a hearing is necessary. Should a hearing
            occur, you may choose whether to participate by video conference or
            by telephone, providing flexibility to accommodate your preferences.
            If your Claim(s) exceeds $10,000, your right to a hearing will be
            determined by the applicable New Era ADR Procedures.  Regardless of
            the amount in dispute and subject to the New Era ADR Procedures
            governing the arbitration, the arbitrator will have the discretion
            to order a reasonable exchange of information between the parties,
            consistent with the expedited nature of the arbitration. MedX will
            bear all arbitration fees and administrative costs as required by
            applicable law. The arbitrator will have the power, upon a showing
            of cause, to seal records and protect the confidentiality of
            proceedings and the award. Each party will pay its own attorneys’
            fees and costs not unique to arbitration when in arbitration, except
            that the arbitrator may award fees and costs to the prevailing party
            to the extent permitted by law. Any dispute as to whether a cost
            would have been required if the claims were litigated in court will
            be resolved by the arbitrator. The arbitrator alone will have the
            authority to determine arbitrability including disputes about the
            formation, scope, applicability, enforceability, or validity of this
            Agreement, except that any dispute concerning the scope or
            enforceability of the Class Waiver must be resolved by a court of
            competent jurisdiction. The parties agree not to engage in actions
            or tactics, made in bad faith, that are frivolous or solely intended
            to cause unnecessary delay or to increase the cost of dispute
            resolution. The arbitrator will be authorized to grant any relief or
            impose any sanctions available under Federal Rule of Civil Procedure
            11, if applicable, or any applicable state law for either party’s
            violation of this requirement.
          </p>
          <p className="mb-4">
            The parties agree that Claims will be resolved on an individual
            basis only, and not on a class, collective, or representative basis
            on behalf of other individuals or Healthcare Professionals,
            including under California’s Private Attorneys General Act (“PAGA”),
            to the fullest extent permitted by applicable law (“Class Waiver”).
            Any claim that all or part of the Class Waiver is invalid,
            unenforceable, unconscionable, void or voidable may be determined
            only by a court. In no case may class, collective, or representative
            claims proceed in arbitration on behalf of other individuals or
            Healthcare Professionals. ‍ The parties agree that to initiate an
            arbitration a party must provide the other party with a written
            demand for arbitration and file the demand with New Era ADR. The
            arbitration will be conducted by a single, neutral arbitrator and
            will be administered pursuant to the applicable New Era ADR Rules
            then in effect (available at: www.neweraadr.com) unless such Rules
            are in conflict with this Agreement, in which case, the Arbitration
            Agreement in these Marketplace Terms will control.  If your Claim(s)
            does not exceed $10,000, then the arbitration will be conducted
            solely based on documents and/or evidence the parties submit to the
            arbitrator, unless either party requests a hearing, or the
            arbitrator determines that a hearing is necessary. Should a hearing
            occur, you may choose whether to participate by video conference or
            by telephone, providing flexibility to accommodate your preferences.
            If your Claim(s) exceeds $10,000, your right to a hearing will be
            determined by the applicable New Era ADR Procedures.  Regardless of
            the amount in dispute and subject to the New Era ADR Procedures
            governing the arbitration, the arbitrator will have the discretion
            to order a reasonable exchange of information between the parties,
            consistent with the expedited nature of the arbitration. MedX will
            bear all arbitration fees and administrative costs as required by
            applicable law. The arbitrator will have the power, upon a showing
            of cause, to seal records and protect the confidentiality of
            proceedings and the award. Each party will pay its own attorneys’
            fees and costs not unique to arbitration when in arbitration, except
            that the arbitrator may award fees and costs to the prevailing party
            to the extent permitted by law. Any dispute as to whether a cost
            would have been required if the claims were litigated in court will
            be resolved by the arbitrator. The arbitrator alone will have the
            authority to determine arbitrability including disputes about the
            formation, scope, applicability, enforceability, or validity of this
            Agreement, except that any dispute concerning the scope or
            enforceability of the Class Waiver must be resolved by a court of
            competent jurisdiction. The parties agree not to engage in actions
            or tactics, made in bad faith, that are frivolous or solely intended
            to cause unnecessary delay or to increase the cost of dispute
            resolution. The arbitrator will be authorized to grant any relief or
            impose any sanctions available under Federal Rule of Civil Procedure
            11, if applicable, or any applicable state law for either party’s
            violation of this requirement.
          </p>
        </Section>
        <Section title=" 27. Waiver and Severability">
          <p className="mb-4">
            No waiver by MedX of any term or condition set out in these
            Marketplace Terms will be deemed a further or continuing waiver of
            such term or condition or a waiver of any other term or condition,
            and any failure of MedX to assert a right or provision under these
            Marketplace Terms will not constitute a waiver of such right or
            provision. If any provision of these Marketplace Terms is held by a
            court or other tribunal of competent jurisdiction to be invalid,
            illegal, or unenforceable for any reason, such provision will be
            eliminated or limited to the minimum extent such that the remaining
            provisions of the Marketplace Terms will continue in full force and
            effect.
          </p>
        </Section>
        <Section title={"28. Miscellaneous"}>
          <p className="mb-4">
            You may not assign or transfer these Marketplace Terms, by operation
            of law or otherwise, without our prior written consent. Any attempt
            by you to assign or transfer these Marketplace Terms without such
            consent will be null and of no effect. We may freely assign or
            transfer these Marketplace Terms without restriction. Subject to the
            foregoing, these Marketplace Terms will bind and inure to the
            benefit of the parties, their successors, and permitted assigns.
          </p>
        </Section>
        <Section title={"29. Your Comments and Concerns"}>
          <p className="mb-4">
            All notices of copyright infringement claims should be sent to
            MedX’s copyright agent mxlegal@medxsvc.com. All other feedback,
            comments, requests for technical support, and other communications
            relating to the Marketplace should be directed to:
            mxsuport@medxsvc.com.
          </p>
        </Section>
      </Card>
    </div>
  );
};
export default TermsAndConditions;
