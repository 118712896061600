import { Skeleton } from "primereact/skeleton";
function OrderSuccessSkeleton() {
  return (
    <div className="flex w-full flex-col gap-4 p-6">
      <div className="flex flex-col items-center">
        <Skeleton width="55%" height="12rem" />
      </div>
      {/* Header section skeleton */}
      <div className="flex flex-col items-center gap-2">
        <Skeleton width="75%" height="2rem" className="mb-2" />
        <Skeleton width="60%" height="1.5rem" />
        <Skeleton width="40%" height="1.5rem" />
      </div>

      {/* Receipt details skeleton */}
      <div className="rounded-lg border p-4">
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <Skeleton width="30%" height="1.5rem" />
            <Skeleton width="20%" height="1.5rem" />
          </div>
          <div className="flex justify-between">
            <Skeleton width="40%" height="1.5rem" />
            <Skeleton width="25%" height="1.5rem" />
          </div>
          <div className="flex justify-between">
            <Skeleton width="35%" height="1.5rem" />
            <Skeleton width="20%" height="1.5rem" />
          </div>
        </div>
      </div>
      <p className="mt-2 text-center font-semibold text-gray-500">
        Please wait while we confirm your payment...
      </p>
    </div>
  );
}

export default OrderSuccessSkeleton;
