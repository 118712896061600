import { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../hooks/useAxios";
import CountdownTimer from "../../../components/helpers/CountdownTimer";
import EmailVerifyIcon from "../../../iconComponents/EmailVerifyIcon";

function ConfirmPhoneNumberDialog({
  sentData,
  setSentData,
  setIsDisabled,
  setDialogVisible,
  VerifyPhoneNumber,
  setIsPhoneVerified,
}) {
  const { http } = useAxios();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canResend, setCanResend] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const OTPDefaultValue = {
    OTP: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: { OTPDefaultValue },
  });

  useEffect(() => {
    const remainingTime = getRemainingTime(sentData?.[VerifyPhoneNumber]);
    const canResend = remainingTime < 0;
    setCanResend(canResend);
  }, [sentData]);

  const getRemainingTime = (lastEdited) => {
    const remainingTime = lastEdited + 2 * 60 * 1000 - Date.now();
    return remainingTime;
  };

  const sendOTP = async () => {
    setIsSending(true);
    try {
      const response = await http.post(
        "/Authentication/SendPhoneNumberConfirmationCode",
        {
          phoneNumber: VerifyPhoneNumber,
        },
      );
      setSentData((old) => ({ ...old, [VerifyPhoneNumber]: Date.now() }));
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
    } catch (error) {
      if (error.response.data.message == "phone number already verified") {
        setIsPhoneVerified(true);
        setIsDisabled(false);
        setDialogVisible(true);
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: error.response.data.message,
          life: 4000,
        });
      } else if (error.response.data.message.includes("Retry After")) {
        toast.current.show({
          severity: "warning",
          summary: "warning",
          detail: error.response.data.message,
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "error",
          detail: error.response.data.message,
          life: 4000,
        });
      }
    } finally {
      setIsSending(false);
    }
  };

  const handleVerifyNumber = async (formData) => {
    try {
      setIsLoading(true);
      const response = await http.post("/Authentication/VerifyPhoneNumber", {
        //****************now put my phone change it later************
        phoneNumber: VerifyPhoneNumber,
        confirmationCode: formData.OTP,
      });
      setIsPhoneVerified(true);
      setIsDisabled(false);
      setDialogVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });
      reset(OTPDefaultValue);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "Verification failed",
        life: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onTimeEnd = () => {
    setCanResend(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="mb-2 flex justify-center">
        <EmailVerifyIcon />
      </div>

      <h4 className="text-center font-Poppins leading-loose text-normal-text">
        verify your phone number to complete setting up your account{" "}
      </h4>
      <p className="mb-5 text-center">
        enter the verification code sent to {VerifyPhoneNumber}
      </p>
      <form onSubmit={handleSubmit(handleVerifyNumber)}>
        <InputText
          name="OTP"
          placeholder="OTP Code"
          className="w-full pb-2 pt-2"
          maxLength={6}
          keyfilter={/^\d+$/}
          {...register("OTP", {
            required: "OTP code is required",
            pattern: {
              value: /^\d{6}$/,
              message: "OTP code must be 6 digits",
            },
          })}
        />
        {errors.OTP && (
          <p className="mt-2 text-red-500">{errors.OTP.message}</p>
        )}
        <div className="mt-4 flex justify-end gap-2">
          <Button
            disabled={sentData?.[VerifyPhoneNumber] && !canResend}
            label={
              sentData?.[VerifyPhoneNumber] ? (
                canResend ? (
                  "Resend OTP"
                ) : (
                  <CountdownTimer
                    durationInMilliseconds={getRemainingTime(
                      sentData?.[VerifyPhoneNumber],
                    )}
                    onTimeEnd={onTimeEnd}
                  />
                )
              ) : (
                "Send OTP"
              )
            }
            link
            severity="info"
            type="button"
            loading={isSending}
            onClick={sendOTP}
          />
          <Button label="Verify" loading={isLoading} />
        </div>
      </form>
    </>
  );
}

export default ConfirmPhoneNumberDialog;
