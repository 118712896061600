import { Button } from "primereact/button";

import useAxios from "../../../hooks/useAxios";
import MedicalHistoryIcon from "../../../iconComponents/MedicalHistoryIcon";

function SystemExpire() {
  const { logOut } = useAxios();

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex w-3/6 justify-center rounded-3xl bg-light-text py-16">
        <div className="2xl:w-5/5 Xl:w-4/5 md:w-5/5 rounded-3xl border border-solid border-light-purple p-10 py-8 sm:w-4/5 lg:w-4/5">
          <span className="m-auto mb-5 flex w-max items-center justify-center rounded-full bg-gray-300 p-2">
            <MedicalHistoryIcon />
          </span>
          <h4 className="text-center font-inter text-title font-extrabold capitalize text-dark-purple">
            Your account has been placed on hold
          </h4>

          <p className="m-auto mt-4 w-4/5 text-center">
            You have been logged out because your account has been disabled or
            deleted by another system admin. You can no longer access the
            system.
          </p>

          <div className="flex justify-center gap-5">
            <div className="flex items-end justify-end">
              <Button
                onClick={() => {
                  logOut();
                }}
                type="submit"
                className="mt-9 justify-center rounded-full bg-light-purple px-10 py-2.5 font-inter font-medium capitalize text-light-text"
              >
                log out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemExpire;
